<template>
  <Sidebar
    v-model:visible="togglePaymentCapabilities"
    position="right"
    class="w-full lg:w-[420px]"
    :dismissable="false"
    :modal="false"
  >
    <template #container="{ closeCallback }">
      <div
        class="flex flex-row w-full justify-between px-4 pt-3 text-spreedly-gray-600"
      >
        <span class="font-semibold text-2xl">{{
          $t("workflows.paymentCapabilities")
        }}</span>
        <Button
          type="button"
          id="close-payment-capabilities-drawer-button"
          @click="closeCallback"
          icon="pi pi-times"
          class="p-button-text p-button-rounded !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          rounded
        ></Button>
      </div>
      <div class="h-full flex flex-col justify-between">
        <div class="flex flex-col justify-start px-4 pt-3 overflow-y-auto">
          <div class="flex flex-col justify-start">
            <h3 class="font-semibold mb-2">
              {{ $t("workflows.paymentMethods") }}
            </h3>
            <div class="flex flex-wrap mb-6">
              <div
                v-for="p in paymentCapabilities.paymentMethods"
                v-tooltip.bottom="tooltipMessage(p)"
                class="border rounded-xl mr-2 mb-2 px-2 whitespace-nowrap text-sm"
                :class="{
                  'bg-white border-spreedly-gray-400': !gatewayTypesList.length,
                  'bg-spreedly-blue-200 border-spreedly-blue-600':
                    gatewayTypesList.length > 0 && supportedCapability(p),
                  'bg-spreedly-gray-200 border-spreedly-gray-400':
                    gatewayTypesList.length > 0 && !supportedCapability(p),
                }"
              >
                {{ p.label }}
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-start">
            <h3 class="font-semibold mb-2">
              {{ $t("workflows.paymentProcessingFeatures") }}
            </h3>
            <div class="flex flex-wrap mb-6">
              <div
                v-for="p in paymentCapabilities.paymentCharacteristics"
                v-tooltip.bottom="tooltipMessage(p)"
                class="border rounded-xl mr-2 mb-2 px-2 whitespace-nowrap text-sm"
                :class="{
                  'bg-white border-spreedly-gray-400': !gatewayTypesList.length,
                  'bg-spreedly-blue-200 border-spreedly-blue-600':
                    gatewayTypesList.length > 0 && supportedCapability(p),
                  'bg-spreedly-gray-200 border-spreedly-gray-400':
                    gatewayTypesList.length > 0 && !supportedCapability(p),
                }"
              >
                {{ p.label }}
              </div>
            </div>
          </div>
          <i18n-t
            v-if="composer"
            class="whitespace-pre-line mt-1"
            scope="global"
            :keypath="'workflows.requestAndResponseFieldsInfo'"
            tag="p"
          >
            <template v-slot:link>
              <a
                :href="`${docsUrl}/docs/normalized-request-and-response-fields`"
                target="_blank"
                id="normalized-request-and-response-fields-link"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                >{{ $t("workflows.requestAndResponseFieldsByGateway") }}
              </a>
            </template>
          </i18n-t>
          <i18n-t
            v-else
            class="whitespace-pre-line mt-1"
            scope="global"
            :keypath="'gateway_.viewDocumentation'"
            tag="p"
          >
            <template v-slot:listOfSupportedGateways>
              <a
                :href="`${docsUrl}/docs/supported-gateways`"
                target="_blank"
                id="normalized-request-and-response-fields-link"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                >{{ $t("gateway_.listOfSupportedGateways") }}
              </a>
            </template>
            <template v-slot:thisGuide>
              <a
                :href="`${docsUrl}/docs/gateway-user-guide`"
                target="_blank"
                id="normalized-request-and-response-fields-link"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                >{{ $t("gateway_.thisGuide") }}
              </a>
            </template>
          </i18n-t>
          <i18n-t
            v-if="composer"
            class="whitespace-pre-line mt-4"
            scope="global"
            :keypath="'workflows.sampleInfo'"
            tag="p"
          >
            <template v-slot:link>
              <a
                href="https://docs.spreedly.com/reference/api/v1/#composer"
                target="_blank"
                id="composer-api-documentation-link"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                >{{ $t("workflows.sampleApiDocumentation") }}
              </a>
            </template>
          </i18n-t>
          <i18n-t
            v-else
            class="whitespace-pre-line mt-4"
            scope="global"
            :keypath="'gateway_.viewDocumentationApiGuide'"
            tag="p"
          >
            <template v-slot:gatewayApiDocumentation>
              <a
                href="https://docs.spreedly.com/reference/api/v1/#gateways"
                target="_blank"
                id="composer-api-documentation-link"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                >{{ $t("gateway_.gatewayApiDocumentation") }}
              </a>
            </template>
          </i18n-t>
        </div>
        <div class="p-4 mb-14" v-if="composer">
          <div class="bg-spreedly-blue-200 p-4 rounded">
            <div class="flex flex-row">
              <mdicon
                name="information"
                class="text-spreedly-blue-700 pr-2 -ml-2"
                data-testid="info-icon"
              ></mdicon>
              <span class="font-bold inline-block">
                {{ $t("workflows.disclosure") }}</span
              >
            </div>

            <i18n-t
              class="whitespace-pre-line mt-1 ml-6"
              scope="global"
              :keypath="'workflows.disclosureText'"
              tag="p"
            ></i18n-t>
          </div>
        </div>
      </div>
    </template>
  </Sidebar>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import { computed, onMounted, ref, watch } from "vue";
import {
  type GatewayOption,
  PaymentMethodMap,
} from "@/services/GatewayService";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useWorkflow } from "@/composables/useWorkflow";
import i18n from "@/i18n";
import { deepCopy, sentenceCase } from "@/services/HelperService";
import { storeToRefs } from "pinia";

type Capability = {
  capabilities: string[];
  label: string;
  supported_gateways: Set<string>;
};

type PaymentCapability = {
  paymentMethods: Capability[];
  paymentCharacteristics: Capability[];
};
const docsUrl = import.meta.env.VITE_DOCS_URL;

const { selectedGatewayTypes } = useWorkflow();
const store = useSettingsStore();
const { gatewayOptions } = storeToRefs(store);
const togglePaymentCapabilities = defineModel<boolean>(
  "togglePaymentCapabilities",
  {
    required: true,
  }
);

const props = defineProps<{
  composer: boolean;
  gatewayType?: string;
}>();

const gatewayTypesList = computed(() => {
  if (!props.composer && props.gatewayType) {
    return [props.gatewayType];
  }
  return selectedGatewayTypes.value || [];
});

const paymentCapabilities = ref<PaymentCapability>({
  paymentMethods: setPaymentMethods(),
  paymentCharacteristics: [
    {
      capabilities: ["network_tokenization"],
      label: "Network tokenization",
      supported_gateways: new Set(),
    },
    {
      capabilities: ["3dsecure_2_mpi_purchase", "3dsecure_2_mpi_authorize"],
      label: "3DS2 global",
      supported_gateways: new Set(),
    },
    {
      capabilities: ["level_2_data", "level_3_data"],
      label: "L2/L3 processing data",
      supported_gateways: new Set(),
    },
    {
      capabilities: ["stored_credentials"],
      label: "Stored credentials",
      supported_gateways: new Set(),
    },
  ],
});

function setPaymentMethods(): Capability[] {
  let arr: Capability[] = [];

  if (props.composer) {
    let composerPaymentMethodTypes = new Set(
      gatewayOptions.value
        .filter((gateway) => gateway.composer_enabled)
        .flatMap((c) => c.payment_methods)
    );
    composerPaymentMethodTypes.forEach((pm) => {
      if (pm) {
        arr.push({
          capabilities: [pm],
          label: PaymentMethodMap[pm] || sentenceCase(pm),
          supported_gateways: new Set(),
        });
      }
    });
  } else {
    new Set(gatewayOptions.value.flatMap((c) => c.payment_methods)).forEach(
      (pm) => {
        arr.push({
          capabilities: [pm],
          label: PaymentMethodMap[pm] || sentenceCase(pm),
          supported_gateways: new Set(),
        });
      }
    );
  }
  return arr;
}
onMounted(() => {
  setPaymentCapabilities();
});

watch(gatewayTypesList, () => {
  Object.keys(paymentCapabilities.value).forEach((key) => {
    paymentCapabilities.value[key as keyof PaymentCapability].forEach(
      (capability: Capability) => {
        capability.supported_gateways = new Set();
      }
    );
  });
  setPaymentCapabilities();
});

function setPaymentCapabilities() {
  gatewayTypesList.value.forEach((gatewayType: string) => {
    setGatewaySupportedCapabilities(gatewayType);
  });
}

function setGatewaySupportedCapabilities(gatewayType: string) {
  const option = gatewayOptions.value.find(
    (option) => option.gateway_type === gatewayType
  );
  if (option && option.payment_methods && option.characteristics) {
    const capabilities = option.payment_methods.concat(option.characteristics);
    capabilities.forEach((capability) => {
      updateSupportedGateway(capability, gatewayType);
    });
  }
}

function updateSupportedGateway(capability: string, gateway_type: string) {
  Object.keys(paymentCapabilities.value).forEach((key: string) => {
    paymentCapabilities.value[key as keyof PaymentCapability]
      .find((c: Capability) => c.capabilities.includes(capability))
      ?.supported_gateways.add(gateway_type);
  });
}

function tooltipMessage(capability: Capability) {
  if (gatewayTypesList.value.length > 0) {
    if (supportedCapability(capability)) {
      return props.gatewayType
        ? i18n.global.t("gateway_.tooltip.available", {
            gateway: getProperGatewayName(props.gatewayType),
          })
        : i18n.global.t("workflows.tooltip.available");
    } else {
      return props.gatewayType
        ? i18n.global.t("gateway_.tooltip.notAvailable", {
            gateway: getProperGatewayName(props.gatewayType),
          })
        : i18n.global.t("workflows.tooltip.notAvailable", {
            gateway: getUnavailableGateways(capability),
          });
    }
  }
}

function getProperGatewayName(gatewayType: string) {
  return gatewayOptions.value.find(
    (option: GatewayOption) => option.gateway_type === gatewayType
  )!.name;
}

function getUnavailableGateways(capability: Capability) {
  let unavailableGateways = deepCopy(gatewayTypesList.value);

  // find all unavailable gateways for capability
  Array.from(capability.supported_gateways).forEach(
    (supportedGateway: string) => {
      unavailableGateways = unavailableGateways.filter(
        (g: string) => g !== supportedGateway
      );
    }
  );

  //remove duplicates
  unavailableGateways = Array.from(new Set(unavailableGateways));
  let unavailableGatewayProperNames: string[] = [];

  //Get human readable gateway names from gatewayOptions array
  unavailableGateways.forEach((g: string) => {
    unavailableGatewayProperNames.push(getProperGatewayName(g));
  });

  // Build string
  if (unavailableGatewayProperNames.length > 0) {
    let gatewayString = "";
    unavailableGatewayProperNames.forEach((gateway, index) => {
      if (
        index === unavailableGatewayProperNames.length - 1 &&
        unavailableGatewayProperNames.length !== 1
      ) {
        gatewayString += ` or ${gateway}`;
      } else if (index === 0) {
        gatewayString += `${gateway}`;
      } else {
        gatewayString += `, ${gateway}`;
      }
    });

    return gatewayString;
  }
}

function supportedCapability(capability: Capability) {
  // chips display as active if no gateways are selected in the workflow
  // chips display as active if all gateways in the workflow support that capability
  // chips display as inactive if at least one gateway in the workflow does not support that capability
  return (
    Array.from(capability.supported_gateways).length ===
    Array.from(new Set(gatewayTypesList.value)).length
  );
}
</script>

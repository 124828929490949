import { get, put, getList, post, httpDelete } from "@/http";
import i18n from "@/i18n";

export const TRANSACTION_METADATA = "transaction_metadata";
export const BIN_RANGE = "bin_range";
export const PAYMENT_METHOD_TYPE = "payment_method_type";

export const PAYMENT_METHOD_GATEWAY_TYPE = "payment_method_gateway_type";

export type WorkflowPayload = {
  workflow: {
    key?: string;
    environment_key?: string;
    name: string;
    inserted_at?: string;
    updated_at?: string;
    version?: number;
    deleted?: boolean;
    steps?: WorkflowStep[];
    sandbox?: boolean;
  };
};

export type WorkflowStep = {
  uuid?: string;
  description?: string;
  condition_set?: WorkflowConditionSet;
  steps?: WorkflowStep[];
  conclusions?: WorkflowConclusion[];
  priority: number;
};

export type WorkflowConditionSet = {
  uuid?: string;
  edit_mode?: boolean; //used purely for collapsing conditions
  operator: ConditionalSetOperator.And | ConditionalSetOperator.Or;
  conditions: WorkflowCondition[];
};

export type WorkflowCondition = {
  uuid?: string;
  condition_type: string;
  comparator: string;
  values: string[] | number[] | TxnMetadata[] | BinRange[];
};

export type TxnMetadata = {
  uuid?: string;
  key: string;
  values: string[];
};

export type BinRange = {
  uuid?: string;
  upper: string;
  lower: string;
};

export type WorkflowConclusion = {
  uuid?: string;
  weight: number;
  result: WorkflowResult;
};

export type WorkflowResult = {
  uuid?: string;
  gateway_key: string;
  gateway_type: string;
  parent_company_id: string;
  priority?: number;
  params: WorkflowResultParams;
};

export type WorkflowResultParams = {
  attempt_network_token: boolean;
  pan_fallback: boolean;
  recover?: WorkflowRecover;
};

export enum RecoverBaseMode {
  Standard = "standard",
  Outage = "outage",
}

export enum ConditionalSetOperator {
  Or = "OR",
  And = "AND",
}

export type WorkflowRecover = {
  config_id: string;
  base_mode: RecoverBaseMode.Standard | RecoverBaseMode.Outage | "";
  results: WorkflowResult[];
};

export type Workflow = {
  key?: string;
  environment_key?: string;
  deleted?: boolean;
  inserted_at?: string;
  updated_at?: string;
  name: string;
  steps: WorkflowStep[];
  sandbox: boolean;
};

export type ConditionType = {
  name: string;
  comparators: string[];
  av_required: boolean;
};

export type Options = {
  currencies: {
    iso_code: string;
    name: string;
    symbol: string;
    number: string;
    thousands_separator: string;
    decimal_mark: string;
  }[];
  payment_method_gateway_types: string[];
  payment_method_types: { gateway_type: string; name: string }[];
  issuing_bank: string[];
  issuing_countries: CountriesOptions[];
  card_type: string[];
  card_category: string[];
  card_brand: string[];
  bin_type: string[];
};

export type CountriesOptions = {
  iso_country_a2: string;
  iso_country_a3: string;
  iso_country_name: string;
};

export async function createWorkflow(
  orgKey: string,
  envKey: string,
  workflow: WorkflowPayload
): Promise<Workflow> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows`;
  return post(url, workflow, "workflow");
}

export async function listWorkflowsByGateway(
  orgKey: string,
  envKey: string,
  gatewayId: string
) {
  return getList<Workflow>(
    `/organizations/${orgKey}/environments/${envKey}/workflows/workflows_by_gateway/${gatewayId}`,
    "workflows"
  );
}

export async function listWorkflowsByRecoverConfiguration(
  orgKey: string,
  envKey: string,
  recoverConfigKey: string
) {
  return getList<Workflow>(
    `/organizations/${orgKey}/environments/${envKey}/workflows/workflows_by_recover_configuration/${recoverConfigKey}`,
    "workflows"
  );
}
export async function listWorkflows(orgKey: string, envKey: string) {
  return getList<Workflow>(
    `/organizations/${orgKey}/environments/${envKey}/workflows`,
    "workflows"
  );
}

export async function updateWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string,
  workflow: WorkflowPayload
): Promise<Workflow> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`;
  return put(url, workflow, "workflow");
}

export async function deleteWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string
): Promise<void> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`;
  return httpDelete(url);
}

export async function fetchWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string
): Promise<Workflow> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`,
    "workflow"
  );
}

export function getGatewayParentCompanies(
  workflowSteps: WorkflowStep[]
): string[] {
  let arr = Array.from(
    new Set(
      JSON.stringify(workflowSteps).match(/"parent_company_id":"[a-z_]*"/g)
    )
  ).map((item) => {
    return item.replace('"parent_company_id":', "").replaceAll('"', "");
  });

  arr.forEach((item, index) => {
    if (!item) arr.splice(index, 1);
  });
  return arr;
}

export async function fetchOptions(
  orgKey: string,
  envKey: string
): Promise<Options> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/workflows/options`
  );
}

export async function fetchConditionTypes(
  orgKey: string,
  envKey: string
): Promise<ConditionType[]> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/workflows/condition_types`,
    "condition_types"
  );
}

export function getWorkflowFeatures(
  workflowSteps: WorkflowStep[],
  avEnabled: boolean
): string[] | [] {
  const workflowFeatures: string[] = [];
  workflowSteps.forEach((step) => {
    if (step.condition_set) {
      workflowFeatures.push(i18n.global.t("workflows.conditions"));
    }
    if (step.conclusions) {
      if (step.conclusions.length > 1) {
        workflowFeatures.push(i18n.global.t("workflows.splitRouting"));
      }
      step.conclusions.forEach((conclusion) => {
        if (avEnabled && conclusion.result.params?.attempt_network_token) {
          workflowFeatures.push(i18n.global.t("networkTokens"));
        }
        if (conclusion.result.params?.recover?.base_mode) {
          workflowFeatures.push(i18n.global.t("recover.recover"));
        }
      });
    }
  });

  return Array.from(new Set(workflowFeatures));
}

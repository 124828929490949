<template>
  <div v-if="config">
    <SpreedlyPageTitle
      breadcrumbs
      :title="$t('recover.createConfiguration')"
      editable-title
    >
      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'RecoverConfigurationList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("recover.recoverConfiguration", 2) }}
        </router-link>
      </template>
      <template v-slot:edit-title>
        <SpreedlyEditableTitle
          :has-permission="store.hasPermission('workflow.update')"
          :required="true"
          v-model:title="config.description"
          v-model:title-update-successful="titleUpdateSuccessful"
          @update-title="updateTitle"
        ></SpreedlyEditableTitle>
      </template>
    </SpreedlyPageTitle>
    <ConfirmDialog group="delete-config-warning" id="delete-dialog">
      <template #container="{ message, rejectCallback, acceptCallback }">
        <div class="bg-white rounded">
          <div class="bg-spreedly-red p-4 text-white text-lg">
            {{ message.header }}
          </div>
          <div
            class="text-spreedly-red flex flex-row align-items-center w-full gap-3 border-bottom-1 surface-border p-4"
          >
            <i class="text-5xl text-primary-500 pi pi-exclamation-triangle"></i>
            <div>
              <span v-if="!configurationWorkflows.length">{{
                message.message
              }}</span>
              <span v-else class="whitespace-pre-line">{{
                $t("recover.configurationsExistOnWorkflow")
              }}</span>
              <ul class="flex flex-col list-disc ml-4">
                <li
                  v-for="workflow in configurationWorkflows"
                  :key="workflow.key"
                >
                  <button
                    class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700"
                    @click="
                      router.push({
                        name: 'WorkflowSettings',
                        params: { id: workflow.key },
                      })
                    "
                  >
                    {{ workflow.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-row justify-end p-4">
            <button
              type="button"
              id="close-confirmation-dialog"
              autofocus
              @click="rejectCallback"
              class="border-4 ml-2 text-spreedly-blue-700 border-spreedly-blue-700 p-1 rounded"
            >
              {{ message.rejectLabel }}
            </button>
            <button
              v-if="!configurationWorkflows.length"
              type="button"
              id="delete-confirmation"
              autofocus
              @click="acceptCallback"
              class="border-4 ml-2 text-white border-spreedly-blue-600 bg-spreedly-blue-600 p-1 rounded"
            >
              {{ message.acceptLabel }}
            </button>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <SpreedlyContainer>
      <form
        class="h-full w-full flex flex-col px-6"
        @keyup.enter="save(v.$invalid)"
        @submit.prevent="save(v.$invalid)"
      >
        <Message
          v-if="router.options.history.state.successMessage"
          class="-mt-8 -mx-14 justify-start"
          severity="success"
        >
          <i18n-t
            scope="global"
            :keypath="'successMessage.recoverConfigCreated'"
            tag="p"
          >
            <template v-slot:config>
              <span class="font-bold">{{ config.description }}</span>
            </template>
          </i18n-t>
        </Message>
        <Message
          v-if="displayErrorMessage"
          class="justify-start -mt-8 -mx-14"
          severity="error"
          :closable="false"
          >{{ displayErrorMessage }}
        </Message>
        <div class="my-6">
          <label
            for="retryable-failure-reasons-multiselect"
            class="font-bold"
            :class="{
              '!text-spreedly-red':
                v.retryable_failure_reasons.$invalid && submitted,
            }"
            >{{ $t("recover.failureReasons") }}</label
          >
          <i18n-t
            scope="global"
            keypath="recover.failureReasonDocumentation"
            tag="p"
            class="whitespace-pre-line text-sm my-1"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                :href="`${docsUrl}/docs/recover-user-guide`"
                target="_blank"
                >{{ $t("seeDocumentation") }}</a
              >
            </template>
          </i18n-t>
          <MultiSelect
            v-if="!isLoading"
            v-model="selectedFailureReasons"
            :showClear="!v.retryable_failure_reasons.$model"
            :disabled="!hasPermission"
            input-id="retryable-failure-reasons-multiselect"
            filter
            :loading="isLoading"
            display="chip"
            @change="updateState()"
            :options="retryableFailureReasonOptions"
            optionLabel="name"
            class="w-full"
            :pt="{
              root: {
                class:
                  v.retryable_failure_reasons.$invalid && submitted
                    ? 'border border-spreedly-red !text-spreedly-red'
                    : 'border border-spreedly-gray-300',
              },
            }"
          />
          <Skeleton v-else height="2.5rem" width="100%"></Skeleton>
          <div v-if="v.retryable_failure_reasons.$invalid && submitted">
            <small class="p-error">{{
              v.retryable_failure_reasons.required.$message
            }}</small>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div
            v-if="hasPermission"
            class="flex flex-shrink-0 flex-wrap items-center justify-start mt-4"
          >
            <SpreedlyButton
              class="mr-4"
              :disabled="!v.$anyDirty || formState === 'saving' || isLoading"
              :text="$t('cancel')"
              id="cancel-create-recover-configuration-button"
              :inverse="true"
              @click="cancel()"
            ></SpreedlyButton>
            <SpreedlyButton
              class="!mr-0"
              :disabled="!v.$anyDirty || formState === 'saving' || isLoading"
              @click="save(v.$invalid)"
              id="create-recover-configuration-button"
              :icon="{ position: 'left', state: formState }"
              :text="$t('save')"
            ></SpreedlyButton>
          </div>
          <div
            v-if="store.hasPermission('workflow.delete')"
            class="flex flex-shrink-0 justify-end mt-4 items-center"
          >
            <button
              @click="confirmDeleteConfig"
              :disabled="isLoading"
              id="delete-recover-configuration"
              class="max-h-[42px] h-full inline-flex disabled:cursor-not-allowed justify-center items-center font-light rounded whitespace-nowrap ml-4 px-2 text-spreedly-blue-600 hover:text-spreedly-blue-700"
            >
              {{ $t("recover.deleteConfiguration") }}
            </button>
          </div>
        </div>
      </form>
    </SpreedlyContainer>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import MultiSelect from "primevue/multiselect";
import Message from "primevue/message";

import {
  deleteRecoverConfiguration,
  type RecoverConfiguration,
} from "@/services/RecoverConfigurationsService";

import {
  fetchRecoverConfiguration,
  listFailureReasons,
  updateRecoverConfiguration,
} from "@/services/RecoverConfigurationsService";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { deepCopy, sentenceCase } from "@/services/HelperService";
import i18n from "@/i18n";

import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import { onBeforeRouteLeave } from "vue-router";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";
import {
  listWorkflowsByRecoverConfiguration,
  type Workflow,
} from "@/services/WorkflowService";
import router from "@/router";
import Skeleton from "primevue/skeleton";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const store = useSettingsStore();
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const titleUpdateSuccessful = ref<boolean | null>(null);
const displayErrorMessage = ref();
const selectedFailureReasons = ref<{ name: string; code: string }[]>([]);
const failureReasons = ref<string[]>([]);
const isLoading = ref(false);
const config = ref<RecoverConfiguration>();
const configurationWorkflows = ref<Workflow[]>([]);
const confirm = useConfirm();
const docsUrl = import.meta.env.VITE_DOCS_URL;

const props = defineProps<{
  id: string;
}>();

const state = reactive({
  retryable_failure_reasons:
    deepCopy(config?.value?.retryable_failure_reasons) || [],
});

const rules = {
  retryable_failure_reasons: { required },
};

const v = useVuelidate(rules, state, { $scope: "update-recover-config" });

const retryableFailureReasonOptions = computed(() => {
  let optionsArray: { name: string; code: string }[] = [];
  failureReasons.value.forEach((reason) => {
    optionsArray.push({ name: sentenceCase(reason), code: reason });
  });
  return optionsArray;
});

const hasPermission = computed(() => {
  return store.hasPermission("workflow.update");
});

async function setRecoverConfiguration() {
  return await fetchRecoverConfiguration(
    store.currentOrganization.key,
    store.currentEnvironment.key!,
    props.id
  );
}

onMounted(async () => {
  isLoading.value = true;
  try {
    config.value = await setRecoverConfiguration();
    if (config.value.retryable_failure_reasons?.length > 0) {
      setSelectedFailureReasons();
    }
    failureReasons.value = await listFailureReasons(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );

    configurationWorkflows.value = await listWorkflowsByRecoverConfiguration(
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      props.id
    );
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("recover.httpError", {
      support: "support@spreedly.com",
    });
  } finally {
    isLoading.value = false;
  }
});

function setSelectedFailureReasons() {
  selectedFailureReasons.value = [];
  config.value?.retryable_failure_reasons.forEach((reason) => {
    selectedFailureReasons.value.push({
      name: sentenceCase(reason),
      code: reason,
    });
  });
}
function cancel() {
  state.retryable_failure_reasons =
    deepCopy(config.value?.retryable_failure_reasons) || [];
  setSelectedFailureReasons();
  reset();
}
function reset() {
  v.value.$reset();
  submitted.value = false;
  isLoading.value = false;
}

const confirmDeleteConfig = () => {
  confirm.require({
    group: "delete-config-warning",
    message: i18n.global.t("confirmations.deleteRecoverConfig.message"),
    header: i18n.global.t("confirmations.deleteRecoverConfig.header", {
      configurationName: config.value?.description,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("recover.deleteConfiguration"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => {
      isLoading.value = true;
      try {
        await deleteRecoverConfiguration(
          store.currentOrganization.key,
          store.currentEnvironment.key!,
          props.id
        );
        await router.push({ name: "RecoverConfigurationList" });
      } catch (err) {
        displayErrorMessage.value = i18n.global.t("recover.deleteError");
      } finally {
        isLoading.value = false;
      }
    },
  });
};

onBeforeRouteLeave(() => {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

async function save(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!hasPermission.value) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  isLoading.value = true;
  try {
    formState.value = "saving";
    config.value = await updateRecoverConfiguration(
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      props.id,
      {
        recover_configuration: {
          environment_key: store.currentEnvironment.key!,
          retryable_failure_reasons: state.retryable_failure_reasons,
        },
      }
    );
    formState.value = "saved";
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  } finally {
    reset();
  }
}
const updateTitle = async (title: string) => {
  try {
    await updateRecoverConfiguration(
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      props.id,
      {
        recover_configuration: {
          environment_key: store.currentEnvironment.key!,
          description: title,
        },
      }
    );
    config.value = await setRecoverConfiguration();
    titleUpdateSuccessful.value = true;
  } catch (err) {
    titleUpdateSuccessful.value = false;
  }
};

function updateState() {
  v.value.$touch();
  state.retryable_failure_reasons = [];
  selectedFailureReasons.value.map((reason) => {
    state.retryable_failure_reasons.push(reason.code);
  });
}

watch(
  () => v.value.$anyDirty,
  () => {
    if (v.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);
</script>

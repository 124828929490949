<template>
  <div v-if="recover">
    <div
      :class="
        recoverAllowed ? 'text-spreedly-gray-600' : 'text-spreedly-gray-400'
      "
    >
      <div class="recover-step">
        <div class="recover-top-line"></div>
        <div
          class="recover-content flex flex-row"
          :class="{ '!border-spreedly-red': !recoverAllowed }"
        >
          <div class="flex flex-col w-full">
            <div class="flex flex-row justify-start">
              <div class="self-center mx-2 flex flex-row">
                <span
                  class="mr-2"
                  :class="{ '!text-spreedly-red': !recoverAllowed }"
                  >{{ $t("recover.recover") }}</span
                >
                <mdicon
                  v-if="!store.currentOrganization.allow_recover"
                  name="information-outline"
                  class="text-spreedly-blue-700 hover:cursor-pointer"
                  data-testid="info-icon"
                  @click="$emit('openRecoverInfo')"
                ></mdicon>
              </div>
              <div class="self-center mx-2">
                <button
                  :id="`open-recover-mode-overlay-${stepIndex}-${conclusionIndex}`"
                  @click="toggleRecoverModeOverlay"
                  :disabled="!recoverAllowed"
                  class="w-[225px] pt-1 rounded-md open-recover-mode-overlay disabled:cursor-not-allowed"
                  type="button"
                >
                  <div
                    class="flex flex-row pl-3 pr-4 justify-end"
                    :class="{
                      'border-spreedly-red': v.base_mode.$invalid && submitted,
                    }"
                  >
                    <div
                      class="rounded-2xl ml-2 text-sm w-full px-2 mb-1 items-center border whitespace-nowrap"
                      :class="{
                        'bg-white border-spreedly-gray-400 text-spreedly-gray-700':
                          v.base_mode.$invalid && !submitted && recoverAllowed,
                        'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':
                          !v.base_mode.$invalid && recoverAllowed,
                        'border-spreedly-red text-spreedly-red':
                          v.base_mode.$invalid && submitted && recoverAllowed,
                        'bg-white border-spreedly-gray-400 text-spreedly-gray-400':
                          !recoverAllowed,
                      }"
                    >
                      <span v-if="v.base_mode.$invalid">
                        {{ $t("recover.select") }}
                      </span>
                      <span v-else>
                        {{
                          v.base_mode.$model === "outage"
                            ? $t("recover.outage")
                            : $t("recover.standardOutage")
                        }}
                      </span>
                    </div>
                    <i
                      class="pi pi-chevron-down self-end -mt-1 ml-4 text-lg"
                      :class="
                        recoverAllowed
                          ? 'text-spreedly-gray-500'
                          : 'text-spreedly-gray-400'
                      "
                    ></i>
                  </div>
                </button>
              </div>
              <div class="self-center mx-2">
                <button
                  class="w-fit self-center disabled:cursor-not-allowed rounded-md pt-1"
                  type="button"
                  :disabled="!recoverAllowed"
                  :id="`open-recover-configurations-overlay-${stepIndex}-${conclusionIndex}`"
                  @click="toggleRecoverConfigurationOverlay"
                >
                  <div class="flex flex-row pl-3 pr-4">
                    <div class="flex flex-row">
                      <div
                        class="rounded-2xl ml-2 text-sm w-fit px-2 mb-1 justify-items-end border whitespace-nowrap"
                        :class="{
                          'bg-white border-spreedly-gray-400 text-spreedly-gray-700':
                            !v.config_id.$model && recoverAllowed,
                          'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':
                            v.config_id.$model && recoverAllowed,
                          'bg-white border-spreedly-gray-400 text-spreedly-gray-400':
                            !recoverAllowed,
                        }"
                      >
                        <span>
                          {{ $t("recover.recoverConfiguration") }}
                        </span>
                      </div>
                    </div>
                    <i
                      class="pi pi-chevron-down -mt-1 ml-4 text-lg"
                      :class="
                        recoverAllowed
                          ? 'text-spreedly-gray-500'
                          : 'text-spreedly-gray-400'
                      "
                    ></i>
                  </div>
                </button>
              </div>
              <OverlayPanel
                :id="`recover-configuration-overlay-panel-${stepIndex}-${conclusionIndex}`"
                ref="recoverConfigurationOverlayPanel"
                :pt="{
                  root: {
                    class: 'rounded-lg -mt-2 font-sans text-spreedly-gray-600',
                  },
                }"
              >
                <div class="flex flex-col">
                  <h1 class="font-bold">
                    {{ $t("recover.recoverConfiguration") }}
                  </h1>
                  <p class="whitespace-pre-line text-sm mb-4">
                    {{ $t("recover.recoverConfigurationInfo") }}
                  </p>
                  <Dropdown
                    :disabled="!recoverConfigurations.length || !hasPermission"
                    v-model="v.config_id.$model"
                    filter
                    aria-label="recover-config-dropdown"
                    :input-id="`recover-config-dropdown-${stepIndex}-${conclusionIndex}`"
                    :show-clear="!!v.config_id.$model"
                    :options="recoverConfigurations"
                    option-value="key"
                    @update:model-value="updateParentComponent()"
                    option-label="description"
                    :placeholder="$t('recover.selectConfiguration')"
                  />
                  <button
                    type="button"
                    :disabled="toggleRecoverConfigurationsDrawer"
                    @click="toggleRecoverConfigurationsDrawer = true"
                    class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 self-start mb-2 ml-1"
                    :id="`create-recover-configuration-${stepIndex}-${conclusionIndex}`"
                  >
                    {{ $t("recover.createUpdateConfiguration") }}
                  </button>
                </div>
              </OverlayPanel>
              <OverlayPanel
                :id="`recover-mode-overlay-panel-${stepIndex}-${conclusionIndex}`"
                ref="recoverModeOverlayPanel"
                :pt="{
                  root: {
                    class: 'rounded-lg -mt-2 font-sans text-spreedly-gray-600',
                  },
                }"
              >
                <div class="flex flex-col">
                  <h1 class="font-bold">{{ $t("recover.recoverMode") }}</h1>
                  <i18n-t
                    scope="global"
                    keypath="recover.docs"
                    tag="p"
                    class="whitespace-pre-line text-sm"
                  >
                    <template v-slot:learnMore>
                      <a
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :href="`${docsUrl}/docs/recover-user-guide`"
                        target="_blank"
                        >{{ $t("learnMore").toLowerCase() }}</a
                      >
                    </template>
                  </i18n-t>
                  <div class="flex flex-col mt-4">
                    <div class="flex flex-row">
                      <RadioButton
                        :disabled="!hasPermission"
                        :name="`outage-radio-option-${stepIndex}-${conclusionIndex}`"
                        :value="RecoverBaseMode.Outage"
                        v-model="v.base_mode.$model"
                        :id="`outage-radio-option-${stepIndex}-${conclusionIndex}`"
                        @update:model-value="updateParentComponent()"
                      />
                      <label
                        class="ml-3 -mt-1 text-spreedly-gray-600 font-bold"
                        :for="`outage-radio-option-${stepIndex}-${conclusionIndex}`"
                      >
                        {{ $t("recover.outage") }}
                      </label>
                    </div>
                    <span class="text-xs whitespace-pre-line ml-8">{{
                      $t("recover.outageDescription")
                    }}</span>
                  </div>
                  <div class="flex flex-col mt-4 mb-4">
                    <div class="flex flex-row">
                      <RadioButton
                        :disabled="!hasPermission"
                        :name="`standard-radio-option-${stepIndex}-${conclusionIndex}`"
                        :value="RecoverBaseMode.Standard"
                        v-model="v.base_mode.$model"
                        :id="`standard-radio-option-${stepIndex}-${conclusionIndex}`"
                        @update:model-value="updateParentComponent()"
                      />
                      <label
                        class="ml-3 -mt-1 text-spreedly-gray-600 font-bold"
                        :for="`standard-radio-option-${stepIndex}-${conclusionIndex}`"
                      >
                        {{ $t("recover.standardOutage") }}
                      </label>
                    </div>
                    <span class="text-xs whitespace-pre-line ml-8">{{
                      $t("recover.standardOutageDescription")
                    }}</span>
                  </div>
                </div>
              </OverlayPanel>
            </div>
            <div class="flex flex-row justify-between mt-4 ml-4">
              <div class="flex flex-row w-full justify-start">
                <div class="self-center mx-2 whitespace-nowrap">
                  {{ $t("sendTo") }}
                </div>
                <div class="self-center mx-2 w-full">
                  <WorkflowResult
                    :step-index="stepIndex"
                    :conclusion-index="conclusionIndex"
                    :result="recover.results[0]"
                    :key="recover.results[0].uuid"
                    :recover="true"
                    :recover-priority="recover.results[0].priority"
                  ></WorkflowResult>
                </div>
              </div>
            </div>
          </div>
          <div class="self-center mx-2">
            <Button
              type="button"
              :aria-label="$t('remove')"
              :id="`remove-recovery-strategy-${stepIndex}-${conclusionIndex}`"
              @click="$emit('removeRecover', conclusionIndex)"
              :disabled="!hasPermission"
              icon="pi pi-times"
              v-tooltip="$t('recover.removeRecoverStrategy')"
              class="remove-recovery-strategy p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </div>
        </div>
        <div class="recover-v-stepper">
          <div class="recover-line"></div>
          <div class="recover-arrow"></div>
        </div>
      </div>
      <div class="recover-step" v-if="recover.results.length > 1">
        <div class="recover-top-line"></div>
        <div class="recover-content flex flex-row ml-14">
          <div class="flex flex-row justify-between">
            <div class="self-center mx-2 whitespace-normal">
              {{ $t("recover.ifFailureSendTo") }}
            </div>
            <div class="self-center mx-2 w-full">
              <WorkflowResult
                :step-index="stepIndex"
                :conclusion-index="conclusionIndex"
                :result="recover.results[1]"
                :key="recover.results[1].uuid"
                :recover="true"
                :recover-priority="recover.results[1].priority"
              ></WorkflowResult>
            </div>
          </div>
          <div
            class="self-center mx-2"
            v-if="recover.results.length > 1 && recoverAllowed"
          >
            <Button
              type="button"
              :aria-label="$t('remove')"
              :id="`remove-recover-result-${stepIndex}-${conclusionIndex}-${recover.results[1].priority}`"
              @click="removeResult(recover.results[1].priority!)"
              :disabled="!hasPermission"
              icon="pi pi-times"
              v-tooltip="$t('recover.removeSecondRecoverResult')"
              class="remove-recover-result p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </div>
        </div>
        <div class="recover-v-stepper">
          <div class="recover-line"></div>
          <div class="recover-arrow"></div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center w-full">
      <Button
        :disabled="!hasPermission"
        v-if="recover.results.length === 1 && recoverAllowed"
        :id="`add-recover-result-button-${conclusionIndex}-${stepIndex}`"
        @click="toggleMenu"
        type="button"
        icon="pi-text-override pi pi-plus-circle"
        class="p-button-text p-button-rounded !text-spreedly-blue-700 bg-[#EEEEEE] !shadow-none hover:enabled:bg-white !hover:bg-white focus:bg-white"
      >
      </Button>
      <Menu
        :ref="(el: Menu & Element) => {
                      menu = el; return undefined
                    }"
        :pt="{ root: { class: 'font-sans' }, action: { ariaHidden: false } }"
        :model="items"
        :id="`recover-overlay-menu-${conclusionIndex}-${stepIndex}`"
        :popup="true"
      />
    </div>
    <WorkflowRecoverConfigurationsDrawer
      v-model:toggle-drawer="toggleRecoverConfigurationsDrawer"
    ></WorkflowRecoverConfigurationsDrawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import WorkflowRecoverConfigurationsDrawer from "@/components/WorkflowRecoverConfigurationsDrawer.vue";
import WorkflowResult from "@/components/WorkflowResult.vue";

import RadioButton from "primevue/radiobutton";
import Menu from "primevue/menu";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  addRecoverResult,
  updateRecover,
  useWorkflow,
  removeRecoverResult,
} from "@/composables/useWorkflow";
import { RecoverBaseMode } from "@/services/WorkflowService";
import type { WorkflowRecover } from "@/services/WorkflowService";
import i18n from "@/i18n";

const recoverAllowed = computed(
  () => isSandbox.value || store.currentOrganization.allow_recover
);
const store = useSettingsStore();
const { action, submitted, recoverConfigurations, isSandbox } = useWorkflow();
const recoverModeOverlayPanel = ref();
const recoverConfigurationOverlayPanel = ref();
const toggleRecoverConfigurationsDrawer = ref(false);
const docsUrl = import.meta.env.VITE_DOCS_URL;
const menu = ref();
const items = ref([
  {
    label: i18n.global.t("recover.addGateway"),
    command: () => {
      v.value.$touch();
      addRecoverResult(props.conclusionIndex, props.stepIndex);
    },
  },
]);

const toggleMenu = (event) => {
  menu.value.toggle(event);
};
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const props = defineProps<{
  recover: WorkflowRecover;
  conclusionIndex: number;
  stepIndex: number;
}>();

defineEmits(["removeRecover", "openRecoverInfo"]);

const state = reactive(props.recover);

const rules = {
  config_id: {},
  base_mode: { required },
  results: {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

function updateParentComponent() {
  v.value.$touch();
  updateRecover(state, props.conclusionIndex, props.stepIndex);
}

function removeResult(priority: number) {
  v.value.$touch();
  removeRecoverResult(props.conclusionIndex, props.stepIndex, priority);
}

const toggleRecoverConfigurationOverlay = (event) => {
  recoverConfigurationOverlayPanel.value.toggle(event);
};

const toggleRecoverModeOverlay = (event) => {
  recoverModeOverlayPanel.value.toggle(event);
};
</script>
<style lang="css" scoped>
.recover-step {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  min-width: 678px;
}

.recover-step:nth-child(3) {
  margin-bottom: 0;
}
.recover-v-stepper {
  position: relative;
}

.recover-step:not(:first-child):not(:last-child) .recover-top-line {
  left: 1rem;
  top: 2rem;
  bottom: 0;
  position: absolute;
  border-left: 2px solid #00538c;
}
.recover-step .recover-line {
  left: 2rem;
  top: 0;
  position: absolute;
  border-left: 2px solid #00538c;
  border-bottom: 2px solid #00538c;
  width: 1.25rem;
  height: 3.4rem;
  border-bottom-left-radius: 1rem;
}

.recover-step:not(:first-child) .recover-line {
  height: 3.5rem;
}

.recover-step .recover-arrow {
  border: solid #00538c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.33rem;
  transform: rotate(-45deg);
  position: absolute;
  left: 2.5rem;
  top: 3rem;
}

.recover-step:last-child .recover-line {
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: -1;
}

.recover-step:last-child .recover-arrow {
  border: 2px solid transparent;
  z-index: -1;
}

.recover-step:last-child {
  padding: 0;
}
.recover-content {
  background-color: white;
  border: solid 2px rgb(229, 231, 235);
  padding: 1rem 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  justify-content: space-between;
}
</style>

<template>
  <Sidebar
    v-model:visible="toggleDrawer"
    position="right"
    class="w-full lg:w-1/2"
    :dismissable="false"
    :modal="true"
    id="recover-configuration-drawer"
  >
    <template #container="{ closeCallback }">
      <div
        class="flex flex-row w-full justify-between px-6 pt-3 text-spreedly-gray-600"
      >
        <span
          class="font-semibold text-2xl"
          v-if="!openEditorWindow || action === 'create'"
          >{{ $t("recover.recoverConfiguration", 2) }}</span
        >
        <SpreedlyEditableTitle
          v-else-if="openEditorWindow && action === 'edit'"
          v-model:title="title"
          :has-permission="store.hasPermission('workflow.update')"
          :required="true"
          v-model:title-update-successful="titleUpdateSuccessful"
          @update-title="updateTitle"
        ></SpreedlyEditableTitle>
        <Button
          type="button"
          id="close-recover-configurations-drawer-button"
          @click="close()"
          icon="pi pi-times"
          class="p-button-text p-button-rounded !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          rounded
        ></Button>
      </div>
      <WorkflowRecoverConfigurationList
        v-if="!openEditorWindow"
        @open-editor="openEditor"
      ></WorkflowRecoverConfigurationList>
      <WorkflowRecoverConfigurationEditor
        v-else
        scope="recover-config"
        :key="selectedConfig ? selectedConfig.key : 'create-config'"
        @close-editor="closeEditor"
        @jump-to-workflow="jumpToWorkflow($event, closeCallback.bind(null))"
        :action="action"
        :config="selectedConfig"
      >
      </WorkflowRecoverConfigurationEditor>
    </template>
  </Sidebar>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import WorkflowRecoverConfigurationList from "@/components/WorkflowRecoverConfigurationList.vue";
import WorkflowRecoverConfigurationEditor from "@/components/WorkflowRecoverConfigurationEditor.vue";
import type { RecoverConfiguration } from "@/services/RecoverConfigurationsService";
import { ref } from "vue";
import {
  listRecoverConfigurations,
  updateRecoverConfiguration,
} from "@/services/RecoverConfigurationsService";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useWorkflow } from "@/composables/useWorkflow";
import { useVuelidate } from "@vuelidate/core";
import i18n from "@/i18n";
import router from "@/router";

const store = useSettingsStore();
const titleUpdateSuccessful = ref<boolean | null>();
const openEditorWindow = ref(false);
const title = ref("New untitled Recover configuration");
const selectedConfig = ref();
const { recoverConfigurations } = useWorkflow();

const toggleDrawer = defineModel<boolean>("toggleDrawer", {
  required: true,
});

const action = ref<"create" | "edit">("create");
const v = useVuelidate({ $scope: "recover-config" });

function close() {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
  closeEditor(false);
  toggleDrawer.value = false;
}

const openEditor = (config?: RecoverConfiguration) => {
  if (config) {
    action.value = "edit";
    selectedConfig.value = config;
    title.value = config.description;
  }
  openEditorWindow.value = true;
};

const closeEditor = async (refreshList: boolean) => {
  if (refreshList) {
    await refreshConfigs();
  }
  openEditorWindow.value = false;
  action.value = "create";
  selectedConfig.value = undefined;
};

const updateTitle = async (newTitle: string) => {
  const response = await updateRecoverConfiguration(
    store.currentOrganization.key,
    store.currentEnvironment.key!,
    selectedConfig.value.key,
    {
      recover_configuration: {
        description: newTitle,
      },
    }
  );
  if (response) {
    await refreshConfigs();
    title.value = response.description;
    titleUpdateSuccessful.value = true;
  }
};

const jumpToWorkflow = async (
  workflowKey: string,
  closeCallbackFn: Function
) => {
  await router.push({
    name: "WorkflowSettings",
    params: { id: workflowKey },
  });

  closeCallbackFn.call(null);
};

async function refreshConfigs() {
  recoverConfigurations.value = await listRecoverConfigurations(
    store.currentOrganization.key,
    store.currentEnvironment.key!
  );
}
</script>

import { computed, ref } from "vue";
import type {
  BinRange,
  ConditionType,
  Options,
  TxnMetadata,
  Workflow,
  WorkflowConclusion,
  WorkflowCondition,
  WorkflowConditionSet,
  WorkflowRecover,
  WorkflowResult,
  WorkflowResultParams,
  WorkflowStep,
} from "@/services/WorkflowService";

import {
  BIN_RANGE,
  ConditionalSetOperator,
  TRANSACTION_METADATA,
} from "@/services/WorkflowService";
import type { RecoverConfiguration } from "@/services/RecoverConfigurationsService";
import type { GatewayConnection } from "@/services/GatewayService";

import { v4 as uuidv4 } from "uuid";
import { deepCopy } from "@/services/HelperService";
import { fetchComposerEnabledGatewayConnections } from "@/services/GatewayService";

const steps = ref<WorkflowStep[]>([]);
const loadingGateways = ref(false);
const buildingWorkflow = ref(true);
const gateways = ref<GatewayConnection[]>([]);
const conditionTypes = ref<ConditionType[]>([]);
const options = ref<Options>({} as Options);
const workflow = ref<Workflow | undefined>(undefined);
const action = ref<"update" | "create">();
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const recoverConfigurations = ref<RecoverConfiguration[]>([]);
const failureReasons = ref<string[]>([]);
const isSandbox = ref<boolean>(false);
export const setNewWorkflow = (currentEnvironmentKey: string) => {
  isSandbox.value = false;
  workflow.value = {
    name: "",
    sandbox: isSandbox.value,
    environment_key: currentEnvironmentKey,
    steps: [newStep(0)],
  };
};

const newStep = (priority: number): WorkflowStep => {
  return {
    uuid: uuidv4(),
    priority: priority,
    description: undefined,
    conclusions: [
      {
        uuid: uuidv4(),
        result: {
          uuid: uuidv4(),
          gateway_key: "",
          gateway_type: "",
          parent_company_id: "",
          params: {
            attempt_network_token: false,
            pan_fallback: false,
          },
        },
        weight: 100,
      },
    ],
  };
};

export async function setGateways(
  orgKey: string,
  envKey: string,
  sandbox: boolean
) {
  loadingGateways.value = true;
  try {
    gateways.value = (
      await fetchComposerEnabledGatewayConnections(orgKey, envKey)
    ).filter((c) => {
      if (sandbox) {
        return c.sandbox || c.gateway_type === "test";
      }
      return !c.sandbox && c.gateway_type !== "test";
    }) as GatewayConnection[];
  } finally {
    loadingGateways.value = false;
  }
}

export const updateRecoverResult = (
  result: WorkflowResult,
  conclusionIndex: number,
  stepIndex: number,
  recoverPriority: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results[recoverPriority]
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results[recoverPriority] = result;
  }
};

export const updateResult = (
  result: WorkflowResult,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (steps.value[stepIndex].conclusions![conclusionIndex].result) {
    // if recover mode was added and then top level result was added do not override recover mode
    if (
      steps.value[stepIndex].conclusions![conclusionIndex].result.params
        ?.recover
    ) {
      // if no params object on result then create it
      if (!result.params) {
        result.params = {} as WorkflowResultParams;
      }

      result.params.recover =
        steps.value[stepIndex].conclusions![
          conclusionIndex
        ].result.params?.recover;

      // Set each recover result attempt_network_token value equal to that of top level result
      // update recover uuid to reload component
      steps.value[stepIndex].conclusions![
        conclusionIndex
      ].result.params?.recover?.results.forEach((r) => {
        r.params.attempt_network_token = result.params.attempt_network_token;
        r.params.pan_fallback = false;
        r.uuid = uuidv4();
      });
    }
    steps.value[stepIndex].conclusions![conclusionIndex].result = result;
  }
};

export const updateCondition = (
  condition: WorkflowCondition,
  stepIndex: number,
  conditionIndex: number
) => {
  if (steps.value[stepIndex].condition_set?.conditions[conditionIndex]) {
    steps.value[stepIndex].condition_set!.conditions[conditionIndex] =
      condition;
  }
};

export const removeCondition = (conditionIndex: number, stepIndex: number) => {
  if (
    steps.value[stepIndex].condition_set?.conditions?.length &&
    steps.value[stepIndex].condition_set!.conditions[conditionIndex]
  ) {
    steps.value[stepIndex].condition_set!.conditions.splice(conditionIndex, 1);
    steps.value[stepIndex].condition_set!.uuid = uuidv4();
  }
};

export const updateRecover = (
  newRecover: WorkflowRecover,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    steps.value[stepIndex].conclusions![conclusionIndex].result.params.recover =
      newRecover;
  }
};

export const addGatewaySplit = (stepIndex: number) => {
  steps.value[stepIndex].conclusions!.push({
    result: {
      uuid: uuidv4(),
      gateway_key: "",
      gateway_type: "",
      parent_company_id: "",
      params: {
        attempt_network_token: false,
        pan_fallback: false,
      },
    },
    weight: 0,
    uuid: uuidv4(),
  });
};

export const addRecovery = (conclusionIndex: number, stepIndex: number) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex] &&
    !steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    steps.value[stepIndex].conclusions![conclusionIndex].result.params.recover =
      {
        config_id: "",
        base_mode: "",
        results: [
          {
            uuid: uuidv4(),
            gateway_key: "",
            gateway_type: "",
            parent_company_id: "",
            params: {
              attempt_network_token:
                steps.value[stepIndex].conclusions![conclusionIndex].result
                  .params.attempt_network_token,
              pan_fallback: false,
            },
            priority: 0,
          },
        ],
      };
    steps.value[stepIndex].conclusions![conclusionIndex].uuid = uuidv4();
    steps.value[stepIndex].conclusions![conclusionIndex].result.uuid = uuidv4();
  }
};

export const addRecoverResult = (
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results?.length === 1
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover?.results.push({
      uuid: uuidv4(),
      gateway_key: "",
      gateway_type: "",
      parent_company_id: "",
      params: {
        attempt_network_token:
          steps.value[stepIndex].conclusions![conclusionIndex].result.params
            .attempt_network_token,
        pan_fallback: false,
      },
      priority: 1,
    });
  }
};

export const removeSplit = (conclusionIndex: number, stepIndex: number) => {
  if (
    steps.value[stepIndex].conclusions!.length > 1 &&
    steps.value[stepIndex].conclusions![conclusionIndex]
  ) {
    steps.value[stepIndex].conclusions!.splice(conclusionIndex, 1);
    steps.value[stepIndex].conclusions![0].weight = 100;
  }
};

export const removeRecoverResult = (
  conclusionIndex: number,
  stepIndex: number,
  priority: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results?.length === 2
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results.splice(priority, 1);
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results[0].priority = 0;
  }
};

export const removeRecoveryFromConclusion = (
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    delete steps.value[stepIndex].conclusions![conclusionIndex].result.params
      .recover;
    // reload result component
    steps.value[stepIndex].conclusions![conclusionIndex].result.uuid = uuidv4();
  }
};
export const removeStep = (priority: number) => {
  const _steps = deepCopy(steps.value) || [];
  _steps.sort((a: WorkflowStep, b: WorkflowStep) => a.priority - b.priority);
  _steps.splice(priority, 1);

  _steps.forEach((step: WorkflowStep, index: number) => {
    step.uuid = uuidv4();
    step.priority = index;
  });

  steps.value = _steps;
};

export const reorderStep = (
  currentPriority: number,
  futurePriority: number
) => {
  const _steps = deepCopy(steps.value) || [];
  _steps.sort((a: WorkflowStep, b: WorkflowStep) => a.priority - b.priority);
  let temp = _steps[currentPriority];
  _steps[currentPriority] = _steps[futurePriority];
  _steps[futurePriority] = temp;

  _steps.forEach((step: WorkflowStep, index: number) => {
    step.uuid = uuidv4();
    step.priority = index;
  });

  steps.value = _steps;
};

const newCondition = () => {
  return { condition_type: "", values: [], comparator: "", uuid: uuidv4() };
};

export const addCondition = (priority: number) => {
  if (steps.value[priority].condition_set?.conditions) {
    steps.value[priority].condition_set!.uuid = uuidv4();
    steps.value[priority].condition_set?.conditions.push(newCondition());
  }
};
export const addStep = (priority: number) => {
  const _steps = deepCopy(steps.value) || [];
  _steps.sort((a: WorkflowStep, b: WorkflowStep) => a.priority - b.priority);
  let step = newStep(priority);
  step.condition_set = {
    uuid: uuidv4(),
    operator: ConditionalSetOperator.And,
    edit_mode: true,
    conditions: [newCondition()],
  };
  _steps.splice(priority, 0, step);

  _steps.forEach((step: WorkflowStep, index: number) => {
    step.uuid = uuidv4();
    if (index !== priority) {
      step.priority = index;
    }
  });

  steps.value = _steps;
};

export const setSteps = (stepsOverride?: WorkflowStep[]) => {
  const _steps = stepsOverride
    ? deepCopy(stepsOverride)
    : deepCopy(workflow.value?.steps) || [];
  _steps.map((s: WorkflowStep) => (s.uuid = uuidv4()));
  _steps.forEach((s: WorkflowStep, index: number) => {
    if (!s.steps && s.conclusions) {
      // every step has a conclusion unless it has a step
      s.conclusions = setConclusions(s.conclusions);
    }
    if (!s.steps && s.condition_set) {
      s.condition_set = setConditionSet(s.condition_set);
    }
    s.uuid = uuidv4();
    s.priority = s.priority || index; //TODO possibly need to rethink this
  });
  _steps.sort((a: WorkflowStep, b: WorkflowStep) => a.priority - b.priority);
  steps.value = _steps;
};

const setConditionSet = (conditionSet: WorkflowConditionSet) => {
  conditionSet.uuid = uuidv4();
  if (conditionSet.conditions?.length) {
    conditionSet.conditions.map((c) => {
      c.uuid = uuidv4();
      if (c.condition_type === TRANSACTION_METADATA) {
        (c.values as TxnMetadata[]).map(
          (v: TxnMetadata) => (v.uuid = uuidv4())
        );
      }

      if (c.condition_type === BIN_RANGE) {
        (c.values as BinRange[]).map((v: BinRange) => (v.uuid = uuidv4()));
      }
    });
    conditionSet.edit_mode = conditionSet.edit_mode || false; // could be true if workflow draft was restored
  } else {
    conditionSet.edit_mode = true;
  }

  return conditionSet;
};
const setConclusions = (conclusions: WorkflowConclusion[]) => {
  conclusions.map((c: WorkflowConclusion) => {
    c.uuid = uuidv4();
    if (c.result) {
      c.result.uuid = uuidv4();
      if (!c.result.params) {
        c.result.params = {
          attempt_network_token: false,
          pan_fallback: false,
        };
      }
      if (c.result.params.recover?.results) {
        c.result.params.recover.results.map((r: WorkflowResult, index) => {
          r.uuid = uuidv4();
          r.priority = r.priority || index;
        });
      }
    }
  });
  return conclusions;
};

export const updateWeight = (
  conclusion: WorkflowConclusion,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (steps.value[stepIndex].conclusions?.length === 2) {
    if (conclusionIndex === 0) {
      steps.value[stepIndex].conclusions![1].weight = 100 - conclusion.weight;
    } else {
      steps.value[stepIndex].conclusions![0].weight = 100 - conclusion.weight;
    }
  }
};

export const resetComposable = () => {
  gateways.value = [];
  workflow.value = undefined;
  steps.value = [];
  conditionTypes.value = [];
  action.value = undefined;
  formState.value = "saveChanges";
  submitted.value = false;
  recoverConfigurations.value = [];
  failureReasons.value = [];
  isSandbox.value = false;
};

const selectedGatewayTypes = computed(() => {
  const arr = Array.from(
    new Set(JSON.stringify(steps.value).match(/"gateway_type":"[a-z0-9_]*"/g))
  ).map((item) => {
    return item.replace('"gateway_type":', "").replaceAll('"', "");
  });
  // create workflow will add an "" gateway, this has to be removed for payment capabilities drawer to open
  if (arr.indexOf("") !== -1) {
    arr.splice(arr.indexOf(""), 1);
  }
  return arr;
});

export function useWorkflow() {
  return {
    steps,
    loadingGateways,
    gateways,
    conditionTypes,
    options,
    workflow,
    buildingWorkflow,
    action,
    formState,
    submitted,
    selectedGatewayTypes,
    failureReasons,
    recoverConfigurations,
    isSandbox,
    addStep,
    reorderStep,
    removeStep,
    setNewWorkflow,
    resetComposable,
    updateResult,
    updateRecoverResult,
    updateRecover,
    updateCondition,
    addCondition,
    removeCondition,
    addRecoverResult,
    addRecovery,
    removeSplit,
    removeRecoverResult,
    removeRecoveryFromConclusion,
    updateWeight,
    addGatewaySplit,
    setSteps,
  };
}

<template>
  <div>
    <SpreedlyPageTitle
      :title="$t('workflows.new')"
      breadcrumbs
      :tag="defaultTag"
    >
      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'WorkflowList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("composer.title") }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <SpreedlySubtitle
      :title="$t('workflows.designer')"
      class="mb-4"
    ></SpreedlySubtitle>
    <WorkflowBuilder v-if="workflow" key="create-workflow"></WorkflowBuilder>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";

import { computed, onBeforeMount, watch } from "vue";

import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { setNewWorkflow, useWorkflow } from "@/composables/useWorkflow";
import WorkflowBuilder from "@/components/WorkflowBuilder.vue";

const store = useSettingsStore();
const { currentEnvironment } = storeToRefs(store);
const { workflow, action } = useWorkflow();

onBeforeMount(() => {
  setNewWorkflow(currentEnvironment.value.key!);
  action.value = "create";
});

const defaultTag = computed(() => {
  return {
    displayTag: !currentEnvironment.value.default_workflow_key,
    severity: "composer",
    value: i18n.global.t("workflows.default"),
  };
});

watch(
  () => currentEnvironment.value.key,
  () => {
    router.push({
      name: "WorkflowList",
    });
  }
);
</script>

<template>
  <div v-if="workflow">
    <SpreedlyPageTitle
      :title="workflow.name"
      breadcrumbs
      editable-title
      :tag="defaultTag"
    >
      <template v-slot:edit-title>
        <SpreedlyEditableTitle
          :has-permission="store.hasPermission('workflow.update')"
          :required="true"
          v-model:title="workflow.name"
          v-model:title-update-successful="titleUpdateSuccessful"
          @update-title="updateTitle"
        ></SpreedlyEditableTitle>
      </template>

      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'WorkflowList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("composer.title") }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <div class="w-full flex flex-row justify-between mb-2">
      <SpreedlySubtitle
        :title="$t('workflows.designer')"
        class="mb-4"
      ></SpreedlySubtitle>
    </div>
    <WorkflowBuilder
      :key="workflow.key"
      action="update"
      @refresh="getWorkflow()"
    ></WorkflowBuilder>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import WorkflowBuilder from "@/components/WorkflowBuilder.vue";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { fetchWorkflow, updateWorkflow } from "@/services/WorkflowService";
import { resetComposable, useWorkflow } from "@/composables/useWorkflow";
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const titleUpdateSuccessful = ref<boolean | null>(null);
const { workflow, action, isSandbox } = useWorkflow();
const props = defineProps<{
  id: string;
}>();

onBeforeMount(async () => {
  await getWorkflow(true);
});

async function getWorkflow(showSuccessBanner?: boolean) {
  try {
    workflow.value = await fetchWorkflow(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      props.id
    );
    isSandbox.value = workflow.value.sandbox;
    action.value = "update";
    if (!showSuccessBanner) router.options.history.state.successMessage = null;
  } catch (error) {
    resetComposable();
    // No workflow returned (it was probably deleted)
    await router.push({ name: "WorkflowList" });
  }
}

const defaultTag = computed(() => {
  return {
    displayTag:
      currentEnvironment.value.default_workflow_key === workflow.value?.key,
    severity: "composer",
    value: i18n.global.t("workflows.default"),
  };
});

watch(
  () => currentEnvironment.value.key,
  () => {
    router.push({
      name: "WorkflowList",
    });
  }
);

watch(
  () => props.id,
  async () => {
    resetComposable();
    await getWorkflow();
  }
);

const updateTitle = async (title: string) => {
  try {
    await updateWorkflow(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      workflow.value?.key as string,
      { workflow: { name: title } }
    );
    await getWorkflow();
    titleUpdateSuccessful.value = true;
  } catch (err) {
    titleUpdateSuccessful.value = false;
  }
};
</script>

<template>
  <div class="flex flex-row">
    <div class="self-center mx-2 flex flex-row">
      <Dropdown
        aria-label="Workflow Gateways Dropdown"
        :disabled="!hasPermission || (!recoverAllowed && recover)"
        :input-id="`workflow-gateway-dropdown-${stepIndex}-${conclusionIndex}`"
        :loading="loadingGateways"
        :show-clear="!!v.gateway_key.$model"
        v-model="v.gateway_key.$model"
        filter
        :filter-fields="['key', 'description', 'gateway_type']"
        :options="gateways"
        :placeholder="$t('gateway_.select')"
        @update:model-value="updateWorkflow()"
        :option-disabled="disableOption"
        option-value="key"
        :pt="{
          input: { class: 'pl-0 pr-2' },
          root: {
            class:
              ((v.gateway_key.$invalid || v.gateway_type.$invalid) &&
                submitted) ||
              (!v.gateway_key.$invalid &&
                !v.gateway_type.$invalid &&
                !selectedGatewayConnection)
                ? '!border-spreedly-red !mb-0  !w-[300px]'
                : '!mb-0 !w-[300px]',
          },
        }"
      >
        <template #dropdownicon>
          <i class="pi pi-chevron-down text-gray-500 self-center text-lg"></i>
        </template>
        <template #value="slotProps">
          <div v-if="v.gateway_key.$model && selectedGatewayConnection">
            <div class="flex flex-row">
              <div class="pr-2 border-r-2">
                <img
                  :src="
                    getPartnerImageSrc(
                      `${
                        gatewayOption(selectedGatewayConnection.gateway_type)
                          ?.partner
                      }_small`
                    )
                  "
                  :alt="`${
                    gatewayOption(selectedGatewayConnection.gateway_type)
                      ?.company_name
                  } logo`"
                  class="object-contain max-h-5 mt-0.5 flex-shrink-0"
                />
              </div>
              <div
                class="px-2 mr-2 text-sm text-ellipsis overflow-hidden whitespace-nowrap"
              >
                {{
                  gatewayOption(selectedGatewayConnection.gateway_type)?.name
                }}
              </div>
            </div>
          </div>
          <div
            v-else-if="
              (v.gateway_key.$invalid && submitted) ||
              (!v.gateway_key.$invalid && !selectedGatewayConnection)
            "
            class="p-error"
          >
            {{ $t("selectionRequired") }}
          </div>
          <div v-else>{{ slotProps.placeholder }}</div>
        </template>
        <template #option="slotProps">
          <div class="flex flex-col text-sm max-w-[300px]">
            <div class="flex flex-row">
              <div class="overflow-hidden text-ellipsis whitespace-nowrap">
                {{ slotProps.option.description }}
              </div>
              <div
                v-if="
                  slotProps.option.sandbox ||
                  slotProps.option.gateway_type === 'test'
                "
                :class="slotProps.option.description ? 'ml-2' : 'mx-0'"
              >
                <div
                  class="px-2 text-xs rounded-xl border border-success-green bg-success-green-light text-spreedly-gray-600"
                >
                  {{ $t("sandbox") }}
                </div>
              </div>
            </div>
            <div>
              {{ gatewayOption(slotProps.option.gateway_type)?.name }}
            </div>
            <div>
              {{ `${$t("token")}: ${slotProps.option.key}` }}
            </div>
          </div>
        </template>
      </Dropdown>
      <Button
        v-if="v.gateway_type.$invalid && submitted"
        icon="pi pi-exclamation-triangle"
        @click="toggleGatewayError"
        rounded
        class="text-spreedly-red hover:text-spreedly-red-dark mx-2"
      />
      <OverlayPanel
        :id="`gateway-error-tooltip-panel-${stepIndex}-${conclusionIndex}`"
        ref="gatewayErrorOverlay"
        :pt="{
          root: 'bg-white border-spreedly-red border-1 border bg-opacity-95 rounded-md font-sans text-sm whitespace-pre-line',
        }"
      >
        <div
          class="flex flex-col"
          v-if="v.gateway_type.hasValidPaymentMethodTypes.$invalid"
        >
          <i18n-t
            scope="global"
            keypath="validations.paymentMethodType"
            tag="p"
            class="text-spreedly-red"
            :plural="
              v.gateway_type.hasValidPaymentMethodTypes.$response.invalid_pms
                .length
            "
          >
            <template v-slot:gateway_type>
              {{ gatewayOption(state.gateway_type)?.name }}
            </template>
            <template v-slot:pm>
              <span
                class="font-bold"
                v-if="
                  v.gateway_type.hasValidPaymentMethodTypes.$response
                    .invalid_pms.length === 1
                "
                >{{
                  sentenceCase(
                    v.gateway_type.hasValidPaymentMethodTypes.$response
                      .invalid_pms[0]
                  )
                }}</span
              >
              <ul v-else>
                <li
                  v-for="pm in v.gateway_type.hasValidPaymentMethodTypes
                    .$response.invalid_pms"
                >
                  - {{ sentenceCase(pm) }}
                </li>
              </ul>
            </template>
          </i18n-t>
          <div class="self-start">
            <Button
              class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
              :id="`open-payment-capabilities-drawer-${stepIndex}-${conclusionIndex}`"
              type="button"
              text
              :label="$t('viewCapabilities')"
              @click="emit('openPaymentCapabilitiesDrawer')"
            ></Button>
          </div>
        </div>
        <div
          v-else-if="v.gateway_type.hasValidPaymentMethodGatewayType.$invalid"
        >
          <i18n-t
            scope="global"
            keypath="validations.paymentMethodGatewayType"
            tag="p"
            class="text-spreedly-red"
          >
            <template v-slot:gateway_type>
              <span class="font-bold">{{
                gatewayOption(
                  v.gateway_type.hasValidPaymentMethodGatewayType.$response
                    .gateway_type
                )?.name
              }}</span>
            </template>
          </i18n-t>
        </div>
      </OverlayPanel>
    </div>
    <div class="self-center mx-2">
      <button
        :id="`open-network-token-overlay-${stepIndex}-${conclusionIndex}`"
        :disabled="recover"
        @click="toggleNetworkTokenOverlay"
        class="w-fit open-network-token-overlay rounded-md -my-1 py-1 -mx-2 px-2"
        type="button"
      >
        <div class="flex flex-row">
          <div class="flex flex-col justify-end">
            <div
              class="rounded-2xl text-sm w-full px-2 mb-1 justify-items-end border whitespace-nowrap"
              :class="{
                'bg-spreedly-gray-200 border-spreedly-gray-200 text-spreedly-gray-400':
                  !isAvEnabled,
                'bg-white border-spreedly-gray-400 text-spreedly-gray-700':
                  isAvEnabled && !v.params.attempt_network_token.$model,
                'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':
                  isAvEnabled && v.params.attempt_network_token.$model,
              }"
            >
              {{ $t("networkTokens") }}
            </div>
            <!--            <div-->
            <!--              v-if="!recover"-->
            <!--              class="rounded-2xl text-sm w-full px-2 border whitespace-nowrap"-->
            <!--              :class="{-->
            <!--                'bg-spreedly-gray-200 border-spreedly-gray-200 text-spreedly-gray-400 ':-->
            <!--                  !isAvEnabled,-->
            <!--                'bg-white border-spreedly-gray-400 text-spreedly-gray-700':-->
            <!--                  isAvEnabled && !v.params.pan_fallback.$model,-->
            <!--                'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':-->
            <!--                  isAvEnabled && v.params.pan_fallback.$model,-->
            <!--              }"-->
            <!--            >-->
            <!--              {{ $t("panFallback") }}-->
            <!--            </div>-->
          </div>
          <i
            v-if="!recover && isAvEnabled"
            class="pi pi-chevron-down text-gray-500 ml-4 self-center text-lg"
          ></i>
          <mdicon
            v-else-if="!recover && !isAvEnabled"
            name="information-outline"
            class="self-center ml-2 text-spreedly-blue-700"
            data-testid="info-icon"
          ></mdicon>
        </div>
      </button>
      <OverlayPanel
        :id="`network-token-overlay-panel-${stepIndex}-${conclusionIndex}`"
        ref="networkTokenOverlayPanel"
        :pt="{
          root: { class: 'rounded-lg -mt-2 font-sans text-spreedly-gray-600' },
        }"
      >
        <div
          class="flex flex-col"
          :class="{
            'bg-transparent p-2': isAvEnabled,
            'bg-spreedly-blue-200 p-4 rounded-md': !isAvEnabled,
          }"
        >
          <div class="flex flex-row">
            <SpreedlyIcon
              v-if="!isAvEnabled"
              name="information"
              class="text-spreedly-blue-700 pr-2 -ml-2"
            ></SpreedlyIcon>
            <h1 class="font-bold">{{ $t("networkTokenization") }}</h1>
          </div>
          <i18n-t
            scope="global"
            keypath="networkTokenDocumentation"
            tag="p"
            class="whitespace-pre-line text-sm"
            :class="{ 'pl-6': !isAvEnabled }"
          >
            <template v-slot:learnMore>
              <a
                class="network-tokenization-docs-link underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                :href="`${docsUrl}/docs/network-tokenization`"
                target="_blank"
                >{{ $t("learnMore") }}</a
              >
            </template>
          </i18n-t>
          <div
            v-if="!store.currentOrganization.allow_payment_method_management"
          >
            <i18n-t
              scope="global"
              keypath="networkTokenizationNotAllowed"
              tag="p"
              class="whitespace-pre-line mt-2 text-sm pl-6"
            >
              <template v-slot:support>
                <a
                  class="mail-spreedly-support-link underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  href="mailto:support@spreedly.com"
                  >{{ $t("spreedlySupport") }}</a
                >
              </template>
            </i18n-t>
          </div>
          <div v-else>
            <div
              v-if="
                !store.currentOrganization.payment_method_management_enabled ||
                !store.currentEnvironment.payment_method_management_enabled
              "
            >
              <i18n-t
                scope="global"
                keypath="advancedVault.networkTokenization.disabled"
                tag="p"
                class="whitespace-pre-line mt-2 text-sm pl-6"
              >
                <template v-slot:settings>
                  <router-link
                    :id="`advanced-vault-settings-link-${stepIndex}-${conclusionIndex}`"
                    class="advanced-vault-settings-link text-spreedly-blue-600 underline hover:text-spreedly-blue-700 hover:cursor-pointer advanced-vault-settings-link"
                    :to="{ name: 'AdvancedVault' }"
                    >{{ $t("advancedVault.advancedVault") }}</router-link
                  >
                </template>
              </i18n-t>
            </div>
          </div>
        </div>
        <div v-if="isAvEnabled" class="m-4">
          <h1 class="pb-2">{{ $t("networkTokens") }}</h1>
          <div class="flex flex-row">
            <Checkbox
              v-model="v.params.attempt_network_token.$model"
              variant="outlined"
              :disabled="!hasPermission"
              @update:modelValue="updateWorkflow()"
              binary
              :input-id="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
              :aria-describedby="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
            />
            <label
              :for="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
              class="ml-2 -mt-1"
            >
              {{ $t("attemptUsingNetworkToken") }}
            </label>
          </div>
          <!--          <div class="flex flex-row mt-2 pl-6">-->
          <!--            <Checkbox-->
          <!--              v-model="v.params.pan_fallback.$model"-->
          <!--              :disabled="-->
          <!--                !v.params.attempt_network_token.$model || !hasPermission-->
          <!--              "-->
          <!--              variant="outlined"-->
          <!--              @update:modelValue="updateWorkflow()"-->
          <!--              binary-->
          <!--              :input-id="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"-->
          <!--              :aria-describedby="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"-->
          <!--            />-->
          <!--            <label-->
          <!--              :for="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"-->
          <!--              class="ml-2 -mt-1"-->
          <!--            >-->
          <!--              {{ $t("fallbackToPAN") }}-->
          <!--            </label>-->
          <!--          </div>-->
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { sentenceCase } from "@/services/HelperService";
import type {
  GatewayConnection,
  GatewayOption,
} from "@/services/GatewayService";
import { getPartnerImageSrc } from "@/services/GatewayService";
import { useSettingsStore } from "@/stores/SettingsStore";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate, type ValidatorResponse } from "@vuelidate/core";
import {
  updateRecoverResult,
  updateResult,
  useWorkflow,
} from "@/composables/useWorkflow";
import Checkbox from "primevue/checkbox";
import {
  PAYMENT_METHOD_GATEWAY_TYPE,
  PAYMENT_METHOD_TYPE,
  type WorkflowCondition,
  type WorkflowResult,
} from "@/services/WorkflowService";
import useEventBus from "@/composables/useEventBus";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";

const { loadingGateways, gateways, action, steps, submitted, isSandbox } =
  useWorkflow();
const store = useSettingsStore();
const networkTokenOverlayPanel = ref();
const docsUrl = import.meta.env.VITE_DOCS_URL;
const { emit } = useEventBus();

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});
const recoverAllowed = computed(
  () => isSandbox.value || store.currentOrganization.allow_recover
);

const props = defineProps<{
  result: WorkflowResult;
  conclusionIndex: number;
  stepIndex: number;
  recover?: boolean;
  recoverPriority?: number;
}>();
const gatewayErrorOverlay = ref();

const gatewayOption = (gateway_type: string): GatewayOption | null => {
  return (
    store.gatewayOptions.find((g) => g.gateway_type === gateway_type) || null
  );
};

const hasValidPaymentMethodGatewayType = helpers.withParams(
  { type: "validPaymentMethodGatewayType" },
  (gateway_type: string): ValidatorResponse => {
    const condition: WorkflowCondition | undefined = steps.value[
      props.stepIndex
    ].condition_set?.conditions.find(
      (c: WorkflowCondition) => c.condition_type === PAYMENT_METHOD_GATEWAY_TYPE
    );
    if (!condition || !condition.values[0]) return { $valid: true };
    return {
      $valid: gateway_type === condition.values[0],
      gateway_type: condition.values[0],
    };
  }
);
const hasValidPaymentMethodTypes = helpers.withParams(
  {
    type: "validPaymentMethodTypes",
  },
  (gateway_type: string): ValidatorResponse => {
    const condition: WorkflowCondition | undefined = steps.value[
      props.stepIndex
    ].condition_set?.conditions.find(
      (c: WorkflowCondition) => c.condition_type === PAYMENT_METHOD_TYPE
    );

    if (!condition || !condition.values.length) return { $valid: true };
    if (!gatewayOption(gateway_type)?.payment_methods) return { $valid: true };

    let invalidPM: string[] = [];
    let valid = true;
    (condition.values as string[]).forEach((item: string) => {
      if (!gatewayOption(gateway_type)?.payment_methods.includes(item)) {
        invalidPM.push(item);
        valid = false;
      }
    });

    return {
      $valid: valid,
      invalid_pms: invalidPM,
    };
  }
);

const state = reactive({
  uuid: props.result.uuid,
  priority: props.result.priority,
  gateway_key: props.result.gateway_key,
  gateway_type: props.result.gateway_type,
  parent_company_id: props.result.parent_company_id,
  params: {
    attempt_network_token: props.result.params.attempt_network_token,
    pan_fallback: props.result.params.pan_fallback,
  },
});

const rules = {
  uuid: {},
  priority: {},
  gateway_key: { required },
  gateway_type: {
    hasValidPaymentMethodTypes,
    hasValidPaymentMethodGatewayType,
  },
  parent_company_id: {},
  params: {
    attempt_network_token: {},
    pan_fallback: {},
  },
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const isAvEnabled = computed(() => {
  return !!(
    store.currentOrganization.allow_payment_method_management &&
    store.currentOrganization.payment_method_management_enabled &&
    store.currentEnvironment.payment_method_management_enabled
  );
});

const selectedGatewayConnection = computed(() => {
  return gateways.value.find((g) => g.key === state.gateway_key);
});

watch(selectedGatewayConnection, () => {
  updateWorkflow();
});
function updateWorkflow() {
  if (!state.params.attempt_network_token) {
    state.params.pan_fallback = false;
  }

  if (selectedGatewayConnection.value) {
    state.gateway_key = selectedGatewayConnection.value?.key;
    state.gateway_type = selectedGatewayConnection.value?.gateway_type;
    state.parent_company_id =
      gatewayOption(selectedGatewayConnection.value?.gateway_type)?.partner ||
      "";
  } else {
    state.gateway_key = "";
    state.gateway_type = "";
    state.parent_company_id = "";
  }

  if (props.recover && (props.recoverPriority || props.recoverPriority === 0)) {
    updateRecoverResult(
      state,
      props.conclusionIndex,
      props.stepIndex,
      props.recoverPriority
    );
  } else {
    updateResult(state, props.conclusionIndex, props.stepIndex);
  }
}

const disableOption = (option: GatewayConnection) => {
  return (
    !props.recover &&
    steps.value[props.stepIndex]
      .conclusions!.map((g) => g.result.gateway_key)
      .includes(option.key)
  );
};

const toggleNetworkTokenOverlay = (event) => {
  networkTokenOverlayPanel.value.toggle(event);
};

const toggleGatewayError = (event) => {
  gatewayErrorOverlay.value.toggle(event);
};
</script>

<template>
  <div class="flex flex-row metadata pb-6">
    <div class="comparator self-start">
      <slot name="comparator"></slot>
      <div class="line"></div>
    </div>
    <div class="comparator-line">
      <div class="line"></div>
    </div>
    <div class="flex flex-row metadata-content">
      <div class="!self-start mx-2 flex flex-col">
        <label
          class="p-float-label text-xs text-[#6c757d] -mt-5 mb-1"
          :class="{ 'p-error': (v.key.$invalid && submitted) || invalidKey }"
          :for="`txn-metadata-key-${stepIndex}-${conditionIndex}-${txnMetadataIndex}`"
          >{{ $t("key") }}</label
        >
        <InputText
          :disabled="!hasPermission"
          v-model="v.key.$model"
          @blur="updateWorkflow"
          :maxlength="50"
          aria-label="transaction metadata key"
          :pt="{
            root: {
              class:
                (v.key.$invalid && submitted) || invalidKey
                  ? 'border !border-spreedly-red !w-[275px] !h-[42px]'
                  : '!h-[42px] !w-[275px]',
            },
          }"
          :id="`txn-metadata-key-${stepIndex}-${conditionIndex}-${txnMetadataIndex}`"
        ></InputText>
        <div v-if="(v.key.$invalid || invalidKey) && submitted">
          <small class="p-error" v-if="invalidKey && v.key.$model"
            >{{ $t("validations.metadata.unique") }}
          </small>
          <small class="p-error" v-else
            >{{ $t("validations.required") }}
          </small>
        </div>
      </div>
      <div class="self-start mx-2 flex flex-col">
        <label
          class="p-float-label text-xs text-[#6c757d] -mt-5 mb-1"
          :class="{ 'p-error': v.values.$invalid && submitted }"
          :for="`txn-metadata-values-${stepIndex}-${conditionIndex}-${txnMetadataIndex}`"
          >{{ $t("dimensions.anyValueCommaSeparated") }}</label
        >
        <Chips
          :input-id="`txn-metadata-values-${stepIndex}-${conditionIndex}-${txnMetadataIndex}`"
          :allow-duplicate="false"
          :add-on-blur="true"
          :disabled="!hasPermission"
          v-model="v.values.$model"
          @update:model-value="validateLength"
          separator=","
          aria-label="transaction metadata values"
          :pt="{
            container: {
              class: {
                'border !border-spreedly-red': v.values.$invalid && submitted,
              },
            },
            root: {
              class: '!w-[275px] !min-h-[42px]',
            },
          }"
        ></Chips>

        <small class="p-error" v-if="v.values.$invalid && submitted"
          >{{ $t("validations.required") }}
        </small>
      </div>
      <slot name="remove"></slot>
    </div>
  </div>
  <div
    class="add"
    v-if="
      txnMetadataIndex + 1 ===
      steps[stepIndex].condition_set.conditions[conditionIndex].values.length
    "
  >
    <slot name="add"></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import { useWorkflow } from "@/composables/useWorkflow";
import type { TxnMetadata } from "@/services/WorkflowService";
import InputText from "primevue/inputtext";
import Chips from "primevue/chips";
import { required } from "@vuelidate/validators";

const { action, submitted, steps } = useWorkflow();
const store = useSettingsStore();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const props = defineProps<{
  stepIndex: number;
  conditionIndex: number;
  txnMetadataIndex: number;
  txnMetadata: TxnMetadata;
  invalidKey: boolean;
}>();

const state = reactive(props.txnMetadata);

const rules = {
  key: { required },
  values: { required },
};

const emit = defineEmits(["addTxnMetadata"]);
const validateLength = (value: string[]) => {
  // if length of new value is greater than 500 remove it as it's invalid
  if (value.length && value[value.length - 1].trim().length > 500) {
    value.splice(value.length - 1, 1);
  }
  updateWorkflow();
};
const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const updateWorkflow = () => {
  if (!v.value.$anyDirty) {
    return;
  }

  let txnMetaData = {
    key: state.key,
    values: state.values.map((v) => v.trim()),
    uuid: props.txnMetadata.uuid,
  };
  emit("addTxnMetadata", props.txnMetadataIndex, txnMetaData);
};
</script>
<style lang="css" scoped>
.metadata:nth-child(2) .comparator {
  width: 98px;
  margin: 0.25rem;
}

.metadata:nth-child(2) {
  position: relative;
}

.metadata {
  margin-left: -0.5rem;
  position: relative;
}

.metadata:nth-child(2) {
  margin-left: -7.1rem;
}
.metadata .comparator {
  z-index: 10;
}

.add {
  margin-left: -3.5rem;
  margin-top: -1.5rem;
}

.add:nth-child(2) {
  margin-top: -1.3rem;
}

.unique-error:not(:last-child) {
  display: none;
}

.metadata:nth-child(2):not(:nth-last-child(2)) .comparator .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  height: calc(100% - 3rem);
  left: 4.7rem;
}
.metadata .comparator-line {
  width: 100px;
  margin-left: -6.3rem;
  position: relative;
  z-index: 0;
}
.metadata:nth-child(3) .comparator-line .line {
  height: calc(100% + 0.3rem);
  margin-top: -1.5rem;
}
.metadata:not(:nth-last-child(1)) .comparator-line .line {
  height: calc(100% + 1.8rem);
  margin-top: -1.5rem;
}

.metadata:not(:nth-child(3)):nth-last-child(1) .comparator-line .line {
  height: 100%;
}
.metadata .comparator-line .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  left: 4.4rem;
}

.metadata:first-child .comparator-line,
.metadata:first-child .comparator-line .line,
.metadata:nth-child(2) .comparator-line .line,
.metadata:not(:nth-child(2)) .comparator {
  display: none;
}
</style>

<template>
  <div>
    <SpreedlyPageTitle :title="$t('user.organization')"></SpreedlyPageTitle>
    <div
      v-if="store.hasPermission('organization.add_user')"
      class="mt-4 md:mt-0 mb-8"
    >
      <div class="relative flex flex-row justify-between xl:w-1/3 w-full">
        <SpreedlyButton
          :text="$t('user.add')"
          :icon="{ position: 'left', name: 'plus' }"
          @click="router.push({ name: 'OrganizationUserCreate' })"
        ></SpreedlyButton>
      </div>
    </div>
    <DataTable
      paginator
      :rows="10"
      striped-rows
      :rowsPerPageOptions="[5, 10, 20, 50]"
      :size="'small'"
      removable-sort
      sortField="email"
      :sort-order="1"
      column-resize-mode="fit"
      :value="userList"
      :pt="{
        root: { class: 'font-sans border rounded-md' },
        headerRow: { class: '!text-spreedly-gray-400 border-b' },
        bodyRow: { class: 'last:border-b-0 border-b' },
        wrapper: { class: 'p-4' },
        paginator: {
          root: {
            class: 'font-sans ',
          },
          rowPerPageDropdown: {
            root: {
              class:
                'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
            },
          },
        },
      }"
    >
      <Column
        v-for="col in headers"
        :field="col.field"
        :header="$t(col.field)"
        :sortable="col.sortable"
        :pt="{
          sort: { class: 'text-spreedly-blue-600' },
          sortIcon: { class: 'text-spreedly-blue-600' },
          headerTitle: { class: '!text-spreedly-gray-600' },
          bodyCell: {
            class: '!text-spreedly-gray-600',
          },
        }"
      >
        <template #body="slotProps">
          <Skeleton v-if="loadingUsers" width="60%" height="1rem" />
          <div v-else>
            <div v-if="col.type === 'DateString'">
              {{ formatDate(slotProps.data[col.field]) }}
            </div>
            <div v-else>
              {{ slotProps.data[col.field] }}
            </div>
          </div>
        </template>
      </Column>
      <Column :header="$t('roles')">
        <template #body="slotProps">
          <Skeleton v-if="loadingUsers" width="60%" height="1rem" />
          <div v-else>
            <Chip
              class="mr-1 px-3 text-sm my-1 whitespace-nowrap text-spreedly-gray-600 !bg-spreedly-gray-300"
              :label="$t(role)"
              v-for="(role, i) in slotProps.data.roles"
              :key="i"
            />
          </div>
        </template>
      </Column>
      <Column
        header="settings"
        v-if="store.hasPermission('organization.assign_roles')"
        :pt="{
          headerTitle: { class: 'hidden' },
          headerCell: { class: '!rounded-tr-md' },
          bodyCell: { class: '!p-0 w-[50px] rounded-r-md' },
        }"
      >
        <template #body="slotProps">
          <Button
            v-if="showButton(slotProps.data.email) && !loadingUsers"
            aria-label="User Settings"
            @click="
              router.push({
                name: 'OrganizationUserSettings',
                params: {
                  id: slotProps.data.key,
                },
              })
            "
            :title="$t('settings')"
            :aria-label="$t('settings')"
            id="edit-user-settings"
            icon="pi pi-cog"
            class="p-button-text p-button-rounded !text-spreedly-gray-500 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          ></Button>
        </template>
      </Column>
      <Column
        header="delete"
        v-if="store.hasPermission('organization.remove_users')"
        :pt="{
          headerTitle: { class: 'hidden' },
          headerCell: { class: '!rounded-tr-md' },
          bodyCell: { class: '!p-0 w-[50px] rounded-r-md' },
        }"
      >
        <template #body="slotProps">
          <Button
            v-if="showButton(slotProps.data.email) && !loadingUsers"
            @click="confirmRemoveUser(slotProps.data)"
            :title="$t('delete')"
            :aria-label="$t('delete')"
            id="remove-user-button"
            icon="pi pi-trash"
            class="p-button-text p-button-rounded !text-spreedly-red !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          ></Button>
        </template>
      </Column>
    </DataTable>
    <ConfirmDialog group="remove-user" id="delete-dialog"></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from "vue";
import { removeUserFromOrg, type User } from "@/services/UserService";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";

import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";

import Chip from "primevue/chip";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";

import { useAuth0 } from "@auth0/auth0-vue";
import { useConfirm } from "primevue/useconfirm";
import i18n from "@/i18n";
import { deepCopy, formatDate } from "@/services/HelperService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";

const { user } = useAuth0();

const store = useSettingsStore();
const { currentOrganization, organizationUsers } = storeToRefs(store);
const userList = ref<User[]>(
  Array.from({ length: 10 }).map((_, i) => prefillUser(i + 1))
);
const loadingUsers = ref(true);
const confirm = useConfirm();

const headers = [
  {
    field: "email",
    sortable: true,
    type: "String",
  },
  {
    field: "firstName",
    sortable: true,
    type: "String",
  },
  {
    field: "lastName",
    sortable: true,
    type: "String",
  },
  {
    field: "lastLoggedIn",
    sortable: true,
    type: "DateString",
  },
];

watch(
  () => currentOrganization.value.key,
  () => {
    loadUsers();
  }
);

onMounted(() => {
  loadUsers();
});

function prefillUser(id: number) {
  return {
    key: id.toString(),
    email: "",
    firstName: "",
    lastName: "",
    lastLoggedIn: "",
    roles: [],
  };
}
function confirmRemoveUser(user: User) {
  confirm.require({
    group: "remove-user",
    defaultFocus: "reject",
    message: i18n.global.t("confirmations.removeUserFromOrganization.message", {
      user: user.email,
    }),
    header: i18n.global.t("confirmations.removeUserFromOrganization.header"),
    icon: "pi pi-exclamation-triangle",
    accept: () => removeUser(user),
  });
}

async function removeUser(user: User) {
  try {
    await removeUserFromOrg(currentOrganization.value.key, user.key);
    await loadUsers();
  } catch (err) {
    alert(i18n.global.t("errorMessage.removeUser"));
  }
}

function showButton(email: string): boolean {
  return !email.includes(`(${i18n.global.t("you")})`);
}

async function loadUsers() {
  loadingUsers.value = true;
  try {
    await store.fillOrganizationUsers(true);
    userList.value = deepCopy(organizationUsers.value).map((u: User) => {
      if (u.email === user.value?.email) {
        u.email = `${u.email} (${i18n.global.t("you")})`;
      }
      return u;
    });
  } finally {
    loadingUsers.value = false;
  }
}
</script>

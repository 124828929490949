<template>
  <div>
    <div v-if="store.hasPermission('organization.create_access_secret')">
      <Message
        severity="success"
        :closable="false"
        class="w-full"
        v-if="newSecretArray.length"
      >
        {{ $t("accessSecret.copySecret") }}
      </Message>
      <Message
        severity="error"
        :closable="false"
        class="w-full"
        v-if="displayErrorMessage"
      >
        {{ $t("errorMessage.generic") }}
      </Message>
      <SpreedlySubtitle class="mt-6 mb-4" :title="$t('accessSecret.add', 2)">
      </SpreedlySubtitle>
      <SpreedlyContainer>
        <form
          class="p-fluid mb-4 text-spreedly-gray-700"
          @submit.prevent="save(v$.$invalid)"
        >
          <div class="field">
            <label
              class="font-bold text-sm"
              for="name-input"
              :class="{ 'p-error': v$.name.$invalid && submitted }"
              >{{ `${$t("accessSecret.create.input")}` }}</label
            >
            <div class="p-float-label mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
              <InputText
                id="name-input"
                v-model.trim="v$.name.$model"
                autofocus
                :class="{ 'p-invalid': v$.name.$invalid && submitted }"
                aria-describedby="name-error"
                type="text"
              />
            </div>
            <div v-if="v$.name.$invalid && submitted" class="-mt-4 mb-4">
              <small class="p-error" v-if="v$.name.required.$invalid">{{
                v$.name.required.$message
              }}</small>
              <small class="p-error" v-else-if="v$.name.maxLength.$invalid">{{
                v$.name.maxLength.$message
              }}</small>
            </div>
          </div>
        </form>

        <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
          <SpreedlyButton
            class="mr-4"
            :text="$t('cancel')"
            :inverse="true"
            :disabled="!v$.$anyDirty || formState === 'saving'"
            @click="reset()"
            id="cancel-create-access-secret-button"
          ></SpreedlyButton>
          <SpreedlyButton
            :disabled="!v$.$anyDirty"
            class="!mr-0"
            :icon="{ position: 'left', state: formState }"
            :text="$t(formState)"
            id="create-access-secret-button"
            @click="save(v$.$invalid)"
          ></SpreedlyButton>
        </div>
      </SpreedlyContainer>
    </div>
    <SpreedlySubtitle class="mt-6 mb-4" :title="$t('accessSecret.all')">
    </SpreedlySubtitle>
    <DataTable
      paginator
      :frozen-value="newSecretArray"
      :rows="10"
      striped-rows
      :rowsPerPageOptions="[5, 10, 20, 50]"
      :size="'small'"
      :rowClass="newSecretClass"
      removable-sort
      column-resize-mode="fit"
      :sort-order="1"
      sort-field="name"
      :value="accessSecrets"
      :pt="{
        root: { class: 'font-sans border rounded-md' },
        headerRow: { class: '!text-spreedly-gray-400 border-b' },
        bodyRow: { class: 'last:border-b-0 border-b' },
        wrapper: { class: 'p-4' },
        paginator: {
          root: {
            class: 'font-sans ',
          },
          rowPerPageDropdown: {
            root: {
              class:
                'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
            },
          },
        },
      }"
    >
      <template #empty>
        <span class="text-spreedly-gray-500 italic">{{
          $t("accessSecret.noAccessSecrets")
        }}</span>
      </template>
      <Column
        v-for="col in headers"
        :field="col.field"
        :header="sentenceCase(col.field)"
        :sortable="col.sortable"
        :pt="{
          sort: { class: 'text-spreedly-blue-600' },
          sortIcon: { class: 'text-spreedly-blue-600' },
          headerTitle: { class: '!text-spreedly-gray-600' },
          bodyCell: {
            class:
              col.field === 'name'
                ? '!text-spreedly-gray-600 rounded-l-md'
                : '!text-spreedly-gray-600',
          },
        }"
      >
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="60%" height="1rem" />
          <div v-else>
            <div v-if="col.type === 'DateString'">
              {{ formatDate(slotProps.data[col.field]) }}
            </div>
            <div v-else>
              {{ slotProps.data[col.field] }}
            </div>
          </div>
        </template>
        <template
          #body="{ data, frozenRow }"
          v-if="col.field === 'access_secret'"
        >
          <Skeleton v-if="isLoading" width="60%" height="1rem" />
          <div v-else>
            <SpreedlyCopyToClipboard
              v-if="frozenRow"
              class="relative inset-y-0.5"
              inline
              :token="data.access_secret"
            ></SpreedlyCopyToClipboard>
            <span v-else>{{ data.access_secret }}</span>
          </div>
        </template>
      </Column>
      <Column
        header="delete"
        v-if="store.hasPermission('access_secret.delete')"
        :pt="{
          headerTitle: { class: 'hidden' },
          headerCell: { class: '!rounded-tr-md' },
          bodyCell: { class: '!p-0 w-[50px] rounded-r-md' },
        }"
      >
        <template #body="slotProps">
          <Button
            v-if="!isLoading"
            @click="confirmDelete(slotProps.data)"
            :title="$t('delete')"
            :aria-label="$t('delete')"
            id="delete-access-secret-button"
            type="button"
            icon="pi pi-trash"
            class="p-button-text p-button-rounded !text-spreedly-red !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          ></Button>
        </template>
      </Column>
    </DataTable>
    <ConfirmDialog
      group="delete-access-secret"
      id="delete-dialog"
    ></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import {
  type AccessSecret,
  deleteAccessSecret,
  fetchAccessSecrets,
  generateAccessSecret,
} from "@/services/AccessSecretService";
import i18n from "@/i18n";
import { useConfirm } from "primevue/useconfirm";
import { required } from "@/i18n/i18n-validators";
import { maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import { formatDate, sentenceCase } from "@/services/HelperService";
import { onBeforeRouteLeave } from "vue-router";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import type { Environment } from "@/services/EnvironmentService";
import Skeleton from "primevue/skeleton";

const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");

const props = defineProps<{
  environment?: Environment;
}>();

const store = useSettingsStore();
const accessSecrets = ref<AccessSecret[]>(
  Array.from({ length: 10 }).map((_, i) => prefillAccessSecret(i + 1))
);
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const confirm = useConfirm();
const submitted = ref(false);
const displayErrorMessage = ref(false);
const newSecretArray = ref<AccessSecret[]>([]);

onMounted(() => {
  loadAccessSecretList();
});

const state = reactive({ name: "" });
const rules = {
  name: {
    required,
    maxLength: maxLength(100),
  },
};

const v$ = useVuelidate(rules, state);
const newSecretClass = (data: AccessSecret) => {
  if (data.key === newSecretArray.value[0]?.key) {
    return ["bg-success-message-green rounded-md"];
  }
};

watch(
  () => currentEnvironment.value.key,
  () => {
    loadAccessSecretList();
  }
);

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

onBeforeRouteLeave(() => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

const isLoading = ref(false);

function reset() {
  submitted.value = false;
  v$.value.$reset();
  state.name = "";
}
function prefillAccessSecret(id: number) {
  return {
    name: "",
    key: id.toString(),
    created_at: "",
    access_secret: "",
  };
}
async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  displayErrorMessage.value = false;
  await loadAccessSecretList();
  formState.value = "saving";
  try {
    let newSecret = await generateAccessSecret(
      state.name,
      currentOrganization.value.key,
      props.environment?.key
    );
    newSecretArray.value = [];
    newSecretArray.value.push(newSecret);
    formState.value = "saved";
    reset();
  } catch (error) {
    displayErrorMessage.value = true;
    formState.value = "saveChanges";
  }
}

async function loadAccessSecretList() {
  isLoading.value = true;
  try {
    accessSecrets.value = await fetchAccessSecrets(
      currentOrganization.value.key,
      props.environment?.key
    ).then((data) => {
      data.forEach(
        (accessSecret) => (accessSecret.access_secret += "**********")
      );
      return data;
    });
  } catch (err) {
    accessSecrets.value = [];
  } finally {
    isLoading.value = false;
  }
}

const headers = [
  {
    field: "name",
    sortable: true,
    type: "String",
  },
  {
    field: "access_secret",
    sortable: true,
    type: "String",
  },
  {
    field: "created_at",
    sortable: true,
    type: "DateString",
  },
];

const confirmDelete = (secret: AccessSecret) => {
  confirm.require({
    group: "delete-access-secret",
    message: i18n.global.t("confirmations.deleteSecret.message"),
    header: i18n.global.t("confirmations.deleteSecret.header", {
      accessSecret: secret.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("accessSecret.delete"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => deleteSecret(secret),
  });
};

async function deleteSecret(secret: { name: string; key: string }) {
  newSecretArray.value = [];
  if (secret.key) {
    isLoading.value = true;
    try {
      await deleteAccessSecret(
        currentOrganization.value.key,
        secret.key,
        props.environment?.key
      );
      await loadAccessSecretList();
    } catch (err) {
      alert(i18n.global.t("errorMessage.generic"));
    } finally {
      isLoading.value = false;
    }
  }
}
</script>

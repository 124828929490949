<template>
  <div
    class="flex flex-row condition mt-2"
    :class="{
      'mt-6':
        v.condition_type.$model === TRANSACTION_METADATA ||
        v.condition_type.$model === BIN_RANGE,
    }"
  >
    <div class="if"></div>
    <div class="operator self-start">
      <slot name="operator"></slot>
      <div
        :class="{
          line:
            (v.condition_type.$model === TRANSACTION_METADATA ||
              v.condition_type.$model === BIN_RANGE) &&
            v.values?.$model &&
            v.values.$model.length > 1,
        }"
      ></div>
    </div>
    <div class="operator-line">
      <div
        class="line"
        :class="{
          '!-mt-[5rem]':
            conditionIndex === 2 &&
            (steps[stepIndex].condition_set!.conditions[1].condition_type === BIN_RANGE
            || steps[stepIndex].condition_set!.conditions[1].condition_type === TRANSACTION_METADATA )
            && (v.condition_type.$model === BIN_RANGE ||
              v.condition_type.$model === TRANSACTION_METADATA),
        }"
      ></div>
    </div>
    <div class="mx-2 flex flex-col self-start">
      <Dropdown
        v-model="v.condition_type.$model"
        filter
        :aria-label="`dimension-select-${stepIndex}-${conditionIndex}`"
        :option-disabled="disableOption"
        :input-id="`dimension-select-${stepIndex}-${conditionIndex}`"
        :show-clear="!!v.condition_type.$model"
        :disabled="!hasPermission"
        :options="conditionOptions"
        option-group-label="group"
        option-group-children="conditions"
        option-label="name"
        option-value="name"
        :placeholder="$t('workflows.selectDimension')"
        :pt="{
          input: { class: 'pl-0 pr-2 mr-2' },
          root: {
            class:
              (v.condition_type.$invalid && submitted) ||
              v.condition_type.isBinDimensionAllowedValidator.$invalid
                ? '!border-spreedly-red !mb-0 !w-[290px] !h-[42px]'
                : '!mb-0 !w-[290px] !h-[42px]',
          },
        }"
      >
        <template #optiongroup="slotProps">
          <div
            class="border-t-0 border-x-0 border-b-1 border -mx-2 px-2 !font-sans text-spreedly-gray-700"
          >
            {{ slotProps.option.group }}
          </div>
        </template>
        <template #value="slotProps">
          <span class="font-sans"
            >{{
              slotProps.value
                ? sentenceCase(slotProps.value).replace("Bin", "BIN")
                : slotProps.placeholder
            }}
          </span>
        </template>
        <template #option="slotProps">
          <span class="font-sans">
            {{ sentenceCase(slotProps.option["name"]).replace("Bin", "BIN") }}
          </span>
        </template>
      </Dropdown>
      <div class="p-error ml-1" v-if="v.condition_type.$invalid">
        <small v-if="v.condition_type.isBinDimensionAllowedValidator.$invalid">
          {{ v.condition_type.isBinDimensionAllowedValidator.$message }}
        </small>
        <small v-else-if="submitted">
          {{ $t("selectionRequired") }}
        </small>
      </div>
    </div>
    <div class="self-start mx-2 flex flex-col">
      <div
        class="flex flex-col"
        v-if="v.condition_type.$model === TRANSACTION_METADATA && v.values"
      >
        <WorkflowConditionTxnMetadata
          v-for="(txnMetadata, index) in v.values.$model"
          :condition-index="conditionIndex"
          :key="(txnMetadata as TxnMetadata).uuid"
          :invalid-key="v.values.$invalid && submitted"
          :step-index="stepIndex"
          :txn-metadata-index="index"
          :txn-metadata="txnMetadata as TxnMetadata"
          @add-txn-metadata="updateTxnMetadata"
        >
          <template v-slot:remove>
            <div
              class="self-start"
              v-if="steps[stepIndex].condition_set!.conditions[conditionIndex].values.length === 1"
            >
              <slot name="remove"></slot>
            </div>
            <Button
              v-else
              type="button"
              :aria-label="$t('remove')"
              :id="`remove-txn-metadata-${stepIndex}-${index}`"
              :disabled="!hasPermission"
              @click="removeIndex(index)"
              icon="pi pi-minus"
              v-tooltip="$t('workflows.removeKeyValue')"
              class="remove-txn-metadata p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </template>
          <template v-slot:comparator
            ><div class="flex flex-col justify-between h-full">
              <div class="flex flex-row justify-end self-start -mt-1">
                <Dropdown
                  v-if="v.values && v.values.$model.length > 1"
                  aria-label="comparator"
                  v-model="v.comparator.$model"
                  @update:model-value="updateWorkflow"
                  :input-id="`transaction-metadata-comparator-${stepIndex}`"
                  :options="[
                    { label: 'AND', value: 'and' },
                    { label: 'OR', value: 'or' },
                  ]"
                  option-value="value"
                  option-label="label"
                  :pt="{
                    root: { class: '!w-[92px] !h-[42px] !mb-0' },
                    input: { class: 'px-0 ' },
                  }"
                />
              </div></div
          ></template>
          <template v-slot:add>
            <Button
              :disabled="!hasPermission"
              :id="`add-txn-metadata-${stepIndex}`"
              @click="addTransactionMetadata()"
              type="button"
              v-tooltip="$t('workflows.addTxnMetadata')"
              icon="pi-text-override pi pi-plus-circle"
              class="p-button-text p-button-rounded !text-spreedly-blue-700 bg-white !shadow-none hover:enabled:text-spreedly-blue-800 !hover:text-spreedly-blue-800 focus:text-spreedly-blue-800"
            >
            </Button>
          </template>
        </WorkflowConditionTxnMetadata>
      </div>

      <div
        class="flex flex-col"
        v-else-if="v.condition_type.$model === BIN_RANGE && v.values"
      >
        <WorkflowConditionBinRange
          v-for="(binRange, index) in v.values.$model"
          :condition-index="conditionIndex"
          :key="(binRange as BinRange).uuid"
          :invalid-key="v.values.$invalid && submitted"
          :step-index="stepIndex"
          :bin-range-index="index"
          :bin-range="binRange as BinRange"
          @add-bin-range="updateBinRange"
        >
          <template v-slot:remove>
            <div
              class="self-start"
              v-if="steps[stepIndex].condition_set!.conditions[conditionIndex].values.length === 1"
            >
              <slot name="remove"></slot>
            </div>
            <Button
              v-else
              type="button"
              :aria-label="$t('remove')"
              :id="`remove-bin-range-${stepIndex}-${index}`"
              :disabled="!hasPermission"
              @click="removeIndex(index)"
              icon="pi pi-minus"
              v-tooltip="$t('workflows.removeBinRange')"
              class="remove-bin-range p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </template>
          <template v-slot:comparator>
            {{ ConditionalSetOperator.Or }}
          </template>
          <template v-slot:add>
            <Button
              :disabled="!hasPermission"
              :id="`add-bin-range-${stepIndex}`"
              @click="addBinRange()"
              type="button"
              v-tooltip="$t('workflows.addBinRange')"
              icon="pi-text-override pi pi-plus-circle"
              class="p-button-text p-button-rounded !text-spreedly-blue-700 bg-white !shadow-none hover:enabled:text-spreedly-blue-800 !hover:text-spreedly-blue-800 focus:text-spreedly-blue-800"
            >
            </Button>
          </template>
        </WorkflowConditionBinRange>
      </div>
      <div class="flex flex-row" v-else>
        <div class="flex flex-col mr-2">
          <Dropdown
            v-model="v.comparator.$model"
            :aria-label="`dimension-comparator-select-${stepIndex}-${conditionIndex}`"
            :input-id="`dimension-comparator-select-${stepIndex}-${conditionIndex}`"
            :show-clear="!!v.comparator.$model"
            :disabled="
              !hasPermission ||
              !v.condition_type.$model ||
              v.condition_type.isBinDimensionAllowedValidator.$invalid
            "
            @update:model-value="updateWorkflow"
            :options="
              conditionTypes.find((c) => c.name === v.condition_type.$model)
                ?.comparators || []
            "
            :placeholder="$t('workflows.selectComparator')"
            :pt="{
              input: { class: 'pl-0 pr-2 mr-2' },
              root: {
                class:
                  (v.comparator.$invalid && submitted) ||
                  v.condition_type.isBinDimensionAllowedValidator.$invalid
                    ? '!border-spreedly-red !mb-0 !w-[275px] !h-[42px]'
                    : '!mb-0 !w-[275px] !h-[42px]',
              },
            }"
          >
            <template #value="slotProps">{{
              slotProps.value
                ? sentenceCase(slotProps.value)
                : slotProps.placeholder
            }}</template>
            <template #option="slotProps">
              {{ sentenceCase(slotProps.option) }}
            </template>
          </Dropdown>
          <small
            class="p-error ml-1"
            v-if="
              v.comparator.$invalid &&
              !v.condition_type.isBinDimensionAllowedValidator.$invalid &&
              submitted
            "
          >
            {{ $t("selectionRequired") }}
          </small>
        </div>

        <div class="self-start mx-2">
          <div
            v-if="dimensionProperties.element === 'multiselect' && v.values"
            class="flex flex-col"
          >
            <MultiSelect
              :aria-label="`dimension-values-multiselect-${stepIndex}-${conditionIndex}`"
              v-model="v.values.$model"
              :disabled="
                !hasPermission ||
                !v.condition_type.$model ||
                v.condition_type.isBinDimensionAllowedValidator.$invalid
              "
              :input-id="`dimension-values-multiselect-${stepIndex}-${conditionIndex}`"
              :filter="dimensionProperties.displayFilter"
              @update:model-value="updateWorkflow"
              :maxSelectedLabels="dimensionProperties.maxLabel"
              :selected-items-label="dimensionProperties.selectedItemsLabel"
              :placeholder="$t('anyValue')"
              :show-toggle-all="dimensionProperties.showToggleAll"
              :filter-fields="dimensionProperties.filterFields"
              :option-value="dimensionProperties.optionValue"
              :option-label="dimensionProperties.optionLabel"
              :options="dimensionProperties.options"
              :virtualScrollerOptions="{ itemSize: 44 }"
              :pt="{
                token: { class: 'text-sm' },
                virtualScroller: {
                  root: {
                    class: 'font-sans !overflow-x-hidden w-[275px]',
                  },
                },
                root: {
                  class:
                    (v.values.$invalid && submitted) ||
                    v.condition_type.isBinDimensionAllowedValidator.$invalid
                      ? 'border !border-spreedly-red !mb-0 !w-[275px] h-[42px]'
                      : 'border border-spreedly-gray-300 !mb-0 !w-[275px] h-[42px]',
                },
                listbox: { class: '!w-[275px]' },
                item: {
                  class: '!overflow-hidden !whitespace-nowrap !text-ellipsis',
                },
              }"
            >
              <template #value="slotProps">
                <div v-if="!slotProps.value || !slotProps.value.length">
                  {{ slotProps.placeholder }}
                </div>
                <div v-else>
                  <div
                    v-if="slotProps.value.length > dimensionProperties.maxLabel!"
                  >
                    {{ dimensionProperties.selectedItemsLabel }}
                  </div>
                  <div v-else>
                    <Chip
                      v-for="item in slotProps.value"
                      :label="
                        v.condition_type.$model === PAYMENT_METHOD_TYPE
                          ? sentenceCase(item)
                          : item
                      "
                      :key="item"
                      removable
                      v-tooltip="
                        v.condition_type.$model === PAYMENT_METHOD_TYPE
                          ? sentenceCase(item)
                          : item
                      "
                      @remove="removeItem(item)"
                      :pt="{
                        root: { class: 'mr-2 ' },
                        removeIcon: {
                          class: '!h-[14px] !w-[14px]',
                        },
                        label: {
                          class:
                            'self-center max-w-[70px] !text-sm font-sans !overflow-hidden !text-ellipsis !whitespace-nowrap ',
                        },
                      }"
                    ></Chip>
                  </div>
                </div>
              </template>
              <template #option="slotProps">
                <div
                  class="font-sans !text-ellipsis !whitespace-nowrap !overflow-hidden min-w-0"
                  v-tooltip="
                    v.condition_type.$model === 'currency'
                      ? `${slotProps.option['iso_code']} - ${slotProps.option['name']}`
                      : dimensionProperties.optionLabel
                      ? `${slotProps.option[dimensionProperties.optionLabel]}`
                      : `${slotProps.option}`
                  "
                >
                  <span v-if="v.condition_type.$model === 'currency'">
                    {{
                      `${slotProps.option["iso_code"]} - ${slotProps.option["name"]}`
                    }}
                  </span>
                  <span v-else>
                    {{
                      dimensionProperties.optionLabel
                        ? `${slotProps.option[dimensionProperties.optionLabel]}`
                        : `${slotProps.option}`
                    }}
                  </span>
                </div>
              </template>
            </MultiSelect>
            <small
              class="p-error ml-1"
              v-if="
                v.values.$invalid &&
                !v.condition_type.isBinDimensionAllowedValidator.$invalid &&
                submitted
              "
              >{{ $t("validations.oneOrMoreRequired") }}</small
            >
          </div>

          <div
            v-else-if="
              dimensionProperties.element === 'dropdown' && v.singleValue
            "
            class="flex flex-col"
          >
            <Dropdown
              aria-label="value"
              v-model="v.singleValue.$model"
              @update:model-value="updateWorkflow"
              :filter="dimensionProperties.displayFilter"
              :filter-fields="dimensionProperties.filterFields"
              :placeholder="dimensionProperties.placeholder"
              :input-id="`dimension-values-dropdown-${stepIndex}`"
              :options="dimensionProperties.options"
              :option-value="dimensionProperties.optionValue"
              :option-label="dimensionProperties.optionLabel"
              :pt="{
                root: {
                  class:
                    v.singleValue.$invalid && submitted
                      ? '!border-spreedly-red !mb-0 !w-[275px] !h-[42px]'
                      : '!mb-0 !w-[275px] !h-[42px]',
                },
                input: { class: 'px-0 ' },
              }"
            >
              <template #header>
                <div
                  class="font-sans bg-spreedly-blue-200 p-4 flex flex-col"
                  v-if="v.condition_type.$model === PAYMENT_METHOD_GATEWAY_TYPE"
                >
                  <div class="flex flex-row">
                    <SpreedlyIcon
                      name="information"
                      class="text-spreedly-blue-700 pr-2 -ml-2"
                    ></SpreedlyIcon>
                    <h1 class="font-bold">
                      {{ sentenceCase(PAYMENT_METHOD_GATEWAY_TYPE) }}
                    </h1>
                  </div>
                  <i18n-t
                    scope="global"
                    keypath="dimensions.paymentMethodGatewayTypeInfo"
                    tag="p"
                    class="text-sm whitespace-pre-line"
                  >
                    <template v-slot:link>
                      <a
                        :href="`${docsUrl}/docs/conditional-routing-rules-guide`"
                        target="_blank"
                        class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                        >{{
                          $t("dimensions.paymentMethodGatewaysDocumentation")
                        }}
                      </a>
                    </template>
                  </i18n-t>
                </div>
              </template>
            </Dropdown>
            <small
              class="p-error ml-1"
              v-if="v.singleValue.$invalid && submitted"
            >
              {{ $t("selectionRequired") }}
            </small>
          </div>
          <div
            v-else-if="dimensionProperties.info && v.amount"
            class="flex flex-col"
          >
            <InputGroup
              :pt="{
                root: {
                  class:
                    v.amount.$invalid && submitted
                      ? 'border !border-spreedly-red p-error !h-[42px] !w-[275px]'
                      : ' !h-[42px] !w-[275px]',
                },
              }"
            >
              <InputGroupAddon v-if="dimensionProperties.info">
                <Button
                  :pt="{ icon: { class: '!text-lg mt-1' } }"
                  icon="pi pi-info-circle"
                  @click="toggleAmountInfo"
                  class="text-spreedly-blue-700 hover:text-spreedly-blue-800"
                />
              </InputGroupAddon>

              <InputNumber
                :aria-label="`dimension-values-input-number-${stepIndex}-${conditionIndex}`"
                :min="v.comparator.$model === 'is_less_than' ? 1 : 0"
                :disabled="!hasPermission"
                :use-grouping="false"
                v-if="dimensionProperties.element === 'input-number'"
                :input-id="`dimension-values-input-number-${stepIndex}-${conditionIndex}`"
                v-model="v.amount.$model"
                @update:model-value="updateWorkflow"
                :placeholder="$t('validations.enterAmountInteger')"
                :pt="{
                  input: {
                    root: {
                      class: '!border-none',
                    },
                  },
                  root: {
                    class: '!mb-0',
                  },
                }"
              ></InputNumber>
            </InputGroup>
            <small
              class="p-error ml-1"
              v-if="v.amount?.$invalid && submitted && v.amount.$model === 0"
              >{{ v.amount.minValue.$message }}</small
            >
            <small
              class="p-error ml-1"
              v-if="v.amount?.$invalid && submitted && v.amount.$model !== 0"
              >{{ $t("validations.required") }}</small
            >
          </div>
        </div>

        <div class="self-start">
          <slot name="remove"></slot>
        </div>
        <OverlayPanel
          :id="`amount-overlay-tooltip-panel-${stepIndex}-${conditionIndex}`"
          ref="amountInfoOverlay"
          :pt="{
            root: 'rounded-lg -mt-2 font-sans text-spreedly-gray-600',
          }"
        >
          <div class="bg-spreedly-blue-200 p-4 rounded-md">
            <div class="flex flex-row">
              <SpreedlyIcon
                name="information"
                class="text-spreedly-blue-700 pr-2 -ml-2"
              ></SpreedlyIcon>
              <h1 class="font-bold">{{ $t("amount") }}</h1>
            </div>
            <i18n-t
              scope="global"
              keypath="workflows.amountDimensionInfo"
              tag="p"
              class="whitespace-pre-line text-sm"
            >
              <template v-slot:doc>
                <a
                  class="amount-dimension-docs-link underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  :href="`${docsUrl}/docs/create-transactions`"
                  target="_blank"
                  >{{ $t("workflows.amountDocumentation") }}</a
                >
              </template>
            </i18n-t>
          </div>
        </OverlayPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import {
  useVuelidate,
  type ValidationRuleWithoutParams,
  type ValidationRuleWithParams,
} from "@vuelidate/core";
import { updateCondition, useWorkflow } from "@/composables/useWorkflow";
import Dropdown from "primevue/dropdown";
import {
  BIN_RANGE,
  ConditionalSetOperator,
  PAYMENT_METHOD_GATEWAY_TYPE,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_METADATA,
} from "@/services/WorkflowService";
import type {
  ConditionType,
  BinRange,
  Options,
  TxnMetadata,
  WorkflowCondition,
  CountriesOptions
} from "@/services/WorkflowService";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import InputNumber from "primevue/inputnumber";
import { deepCopy, sentenceCase } from "@/services/HelperService";
import i18n from "@/i18n";
import Button from "primevue/button";

import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import { helpers, minValue, required } from "@vuelidate/validators";
import OverlayPanel from "primevue/overlaypanel";
import WorkflowConditionTxnMetadata from "@/components/WorkflowConditionTxnMetadata.vue";
import { v4 as uuidv4 } from "uuid";
import WorkflowConditionBinRange from "@/components/WorkflowConditionBinRange.vue";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import useEventBus from "@/composables/useEventBus";
const docsUrl = import.meta.env.VITE_DOCS_URL;
const { emit } = useEventBus();

const amountInfoOverlay = ref();

const { action, submitted, conditionTypes, options, steps } = useWorkflow();
const store = useSettingsStore();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const props = defineProps<{
  stepIndex: number;
  conditionIndex: number;
  condition: WorkflowCondition;
}>();

type ConditionState = {
  amount: number | undefined;
  condition_type: string;
  comparator: string;
  values: string[] | number[] | TxnMetadata[] | BinRange[];
  singleValue: string | undefined;
};

const isAvEnabled = computed(() => {
  return !!(
    store.currentOrganization.allow_payment_method_management &&
    store.currentOrganization.payment_method_management_enabled &&
    store.currentEnvironment.payment_method_management_enabled
  );
});

const binConditions = conditionTypes.value
  .filter((t) => t.av_required)
  .map((t) => t.name);

const toggleAmountInfo = (event) => {
  amountInfoOverlay.value.toggle(event);
};

const conditionTypeList = computed(() => {
  return (
    steps.value[props.stepIndex].condition_set?.conditions.map((c) => {
      return c.condition_type;
    }) || []
  );
});

const disableOption = (option: ConditionType) => {
  return conditionTypeList.value.includes(option.name);
};

const isUnique = helpers.withParams(
  { type: "isUnique" },
  (value: TxnMetadata[]) => {
    let keyArray = value
      .map((v) => v.key.trim().toLowerCase())
      .filter((f) => f !== "");
    let keySet = new Set(keyArray);
    return keyArray.length === keySet.size;
  }
);

const isUniqueValidator = helpers.withMessage(
  i18n.global.t("validations.metadata.unique"),
  isUnique
);

const isBinDimensionAllowed = helpers.withParams(
  { type: "binDimensionAllowed" },
  (value: string) => {
    return isAvEnabled.value || !binConditions.includes(value);
  }
);
const isBinDimensionAllowedValidator = helpers.withMessage(
  i18n.global.t("validations.binDimensionAllowed"),
  isBinDimensionAllowed
);

const state = reactive<ConditionState>({
  condition_type: props.condition.condition_type,
  comparator: props.condition.comparator,
  values: props.condition.values,
  amount: setAmount(props.condition.values),
  singleValue: setSingleValue(props.condition.values),
});

const conditionOptions = computed(() => {
  return [
    {
      group: i18n.global.t("workflows.standardDimensions"),
      conditions: conditionTypes.value.filter((c) => !c.av_required),
    },
    {
      group: i18n.global.t("workflows.binMetadataDimensions"),
      conditions: conditionTypes.value.filter((c) => c.av_required),
    },
  ];
});

const rules = computed(() => {
  const localRules: {
    condition_type: {
      required: ValidationRuleWithoutParams;
      isBinDimensionAllowedValidator: ValidationRuleWithParams<object, any>;
    };
    comparator: { required: ValidationRuleWithoutParams };
    values?: {
      required: ValidationRuleWithoutParams;
      isUniqueValidator?: ValidationRuleWithParams<object, any>;
    };
    amount?: {
      required: ValidationRuleWithoutParams;
      minValue: ValidationRuleWithParams<{ min: number }, any>;
    };
    singleValue?: {
      required: ValidationRuleWithoutParams;
    };
  } = {
    condition_type: { required, isBinDimensionAllowedValidator },
    comparator: { required },
  };

  if (state.condition_type === "amount") {
    localRules.amount = {
      required,
      minValue: minValue(state.comparator === "is_less_than" ? 1 : 0),
    };
  } else if (state.condition_type === TRANSACTION_METADATA) {
    localRules.values = { required, isUniqueValidator };
  } else if (state.condition_type === PAYMENT_METHOD_GATEWAY_TYPE) {
    localRules.singleValue = { required };
  } else {
    localRules.values = { required };
  }
  return localRules;
});
const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

watch(
  () => state.condition_type,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (binConditions.includes(oldValue) && !isAvEnabled.value) {
        emit("removeAvBinError");
      }
      resetState();
    }
    updateWorkflow();
  }
);

function addTransactionMetadata() {
  if (state.values?.length) {
    (state.values as TxnMetadata[]).push({
      key: "",
      values: [],
      uuid: uuidv4(),
    });
    v.value.$touch();
    updateWorkflow();
  }
}

function addBinRange() {
  if (state.values?.length) {
    (state.values as BinRange[]).push({ upper: "", lower: "", uuid: uuidv4() });
    v.value.$touch();
    updateWorkflow();
  }
}
const removeIndex = (index: number) => {
  if (state.values?.length && state.values[index]) {
    emit(
      "removeCondition",
      deepCopy(steps.value),
      sentenceCase(state.condition_type).replace("Bin", "BIN")
    );
    state.values.splice(index, 1);
    v.value.$touch();
    updateWorkflow();
  }
};

function setSingleValue(
  values: string[] | number[] | TxnMetadata[] | BinRange[]
) {
  if (
    props.condition.condition_type === PAYMENT_METHOD_GATEWAY_TYPE &&
    values.length &&
    values[0] &&
    typeof values[0] === "string"
  ) {
    return values[0];
  }
  return undefined;
}

function setAmount(values: string[] | number[] | TxnMetadata[] | BinRange[]) {
  if (
    props.condition.condition_type === "amount" &&
    values.length &&
    (values[0] || values[0] === 0)
  ) {
    if (typeof values[0] === "number") {
      return values[0];
    } else if (typeof values[0] === "string") {
      return parseInt(values[0]) || undefined;
    }
    return undefined;
  }
  return undefined;
}

const dimensionProperties = computed(() => {
  switch (state.condition_type) {
    case "amount":
      return {
        info: i18n.global.t("workflows.amountDimensionInfo"),
        element: "input-number",
      };
    case "bin_type":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        options: options.value["bin_type" as keyof Options] || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("binTypesSelected", {
          count: state.values.length,
        }),
      };
    case "card_brand":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        options: options.value["card_brand" as keyof Options] || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("dimensions.cardBrandsSelected", {
          count: state.values.length,
        }),
      };
    case "card_category":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        options: options.value["card_category" as keyof Options] || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("dimensions.cardCategoriesSelected", {
          count: state.values.length,
        }),
      };
    case "card_type":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        options: options.value["card_type" as keyof Options] || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("dimensions.cardTypesSelected", {
          count: state.values.length,
        }),
      };
    case "currency":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        filterFields: ["iso_code", "name"],
        optionValue: "iso_code",
        optionLabel: "iso_code",
        options: options.value["currencies" as keyof Options] || [],
        maxLabel: 3,
        selectedItemsLabel: i18n.global.t("dimensions.currenciesSelected", {
          count: state.values.length,
        }),
      };
    case "issuing_bank":
      return {
        element: "multiselect",
        showToggleAll: false,
        displayFilter: true,
        options: options.value["issuing_bank" as keyof Options] || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("issuingBanksSelected", {
          count: state.values.length,
        }),
      };
    case "issuing_country":
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: true,
        filterFields: ["iso_country_name", "iso_country_a3", "iso_country_a2"],
        optionValue: "iso_country_a3",
        optionLabel: "iso_country_name",
        options:
          (
            options.value[
              "issuing_countries" as keyof Options
            ] as CountriesOptions[]
          ).sort((a, b) =>
            a.iso_country_name.localeCompare(b.iso_country_name)
          ) || [],
        maxLabel: 3,
        selectedItemsLabel: i18n.global.t("issuingCountriesSelected", {
          count: state.values.length,
        }),
      };
    case PAYMENT_METHOD_TYPE:
      return {
        element: "multiselect",
        showToggleAll: true,
        filterFields: ["label"],
        displayFilter: true,
        optionValue: "code",
        optionLabel: "label",
        options:
          (
            options.value["payment_method_types" as keyof Options] as string[]
          ).map((pm: string) => {
            return { code: pm, label: sentenceCase(pm) };
          }) || [],
        maxLabel: 2,
        selectedItemsLabel: i18n.global.t("dimensions.paymentMethodsSelected", {
          count: state.values.length,
        }),
      };
    case PAYMENT_METHOD_GATEWAY_TYPE:
      return {
        placeholder: i18n.global.t("selectGatewayType"),
        element: "dropdown",
        displayFilter: true,
        filterFields: ["gateway_type", "name"],
        optionValue: "gateway_type",
        optionLabel: "name",
        options:
          options.value["payment_method_gateway_types" as keyof Options] || [],
      };
    default:
      return {
        element: "multiselect",
        showToggleAll: true,
        displayFilter: false,
        filterFields: [],
        optionValue: "",
        optionLabel: "",
        options: [],
        maxLabel: 3,
        selectedItemsLabel: "",
      };
  }
});
function resetState(): void {
  state.comparator = setComparator();
  state.values = setValues();
  state.amount = undefined;
  state.singleValue = undefined;
}
function setValues() {
  switch (state.condition_type) {
    case TRANSACTION_METADATA:
      return [{ key: "", values: [], uuid: uuidv4() }];
    case BIN_RANGE:
      return [{ lower: "", upper: "", uuid: uuidv4() }];
    default:
      return [];
  }
}

function setComparator() {
  switch (state.condition_type) {
    case TRANSACTION_METADATA:
      return "and";
    case BIN_RANGE:
      return "is_between";
    case PAYMENT_METHOD_TYPE:
      return "is";
    case PAYMENT_METHOD_GATEWAY_TYPE:
      return "is_equal_to";
    default:
      return "";
  }
}
const removeItem = (item: unknown) => {
  state.values.splice(state.values.indexOf(item), 1);
  v.value.$touch();
  updateWorkflow();
};

const updateTxnMetadata = (index: number, metadata: TxnMetadata) => {
  if (state.values.length && state.values[index]) {
    state.values[index] = metadata;
    v.value.$touch();
    updateWorkflow();
  }
};

const updateBinRange = (index: number, binRange: BinRange) => {
  if (state.values.length && state.values[index]) {
    state.values[index] = binRange;
    v.value.$touch();
    updateWorkflow();
  }
};
const updateWorkflow = () => {
  if (!v.value.$anyDirty) {
    return;
  }
  const stateCopy = deepCopy(state);

  const condition = {
    uuid: steps.value[props.stepIndex]!.condition_set!.conditions[
      props.conditionIndex
    ].uuid,
    condition_type: stateCopy.condition_type,
    comparator: stateCopy.comparator,
    values: setConditionValues(stateCopy),
  };

  updateCondition(condition, props.stepIndex, props.conditionIndex);
};

function setConditionValues(
  state: ConditionState
): number[] | string[] | TxnMetadata[] | BinRange[] {
  switch (state.condition_type) {
    case "amount":
      return [state.amount as number];
    case TRANSACTION_METADATA:
      return state.values as TxnMetadata[];
    case BIN_RANGE:
      return state.values as BinRange[];
    case PAYMENT_METHOD_GATEWAY_TYPE:
      return [state.singleValue as string];
    default:
      return state.values as string[];
  }
}
</script>
<style lang="css">
.condition .if {
  align-self: start;
  padding: 0.5rem 1rem 0.5rem 0;
  height: 42px;
  width: 98px;
  margin-left: 0.5rem;
  text-align: center;
}
.condition:nth-child(2) {
  position: relative;
}
.condition .operator {
  width: 98px;
  margin-left: 0.5rem;
  z-index: 5;
}

.condition:not(:nth-child(2)) .operator,
.condition:not(:first-child) .if,
.condition:first-child .operator-line,
.condition:nth-child(2) .operator-line {
  display: none;
}
.condition .operator-line {
  width: 98px;
  margin-left: 0.5rem;
  position: relative;
}
.condition:nth-child(3):last-child .operator-line .line {
  margin-top: -3rem;
  height: calc(100% + 3rem);
  z-index: 0;
}

.condition:nth-child(2) .operator .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  height: calc(100% - 2rem);
  left: 4.5rem;
}

.condition:nth-child(3):not(:last-child) .operator-line .line {
  margin-top: -3rem;
  height: calc(100% + 5rem);
}

.condition:not(:nth-child(3)):last-child .operator-line .line {
  height: calc(100% + 1rem);
}

.condition .operator-line .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  left: 4rem;
  height: calc(100% + 4rem);
  margin-top: -1rem;
}

.condition:first-child .if::after {
  content: "If";
}
</style>

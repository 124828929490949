<template>
  <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">
    <div
      class="border-[3px] rounded-2xl shadow-md h-48"
      :class="{
        'border-spreedly-blue-500': connection.connected,
        'border-spreedly-gray-500': !connection.connected,
      }"
      v-for="connection in connections"
      :key="connection.gateway_type"
    >
      <div
        :class="{
          'bg-spreedly-blue-600': connection.connected,
          'bg-spreedly-gray-500': !connection.connected,
        }"
        class="pb-3 flex flex-row justify-between rounded-t-xl relative"
      >
        <div
          class="w-2/3 p-4 text-white font-bold text-lg xl:text-xl 2xl:text-2xl whitespace-nowrap"
        >
          {{ connection.name }}
        </div>
        <img
          v-if="connection.certified"
          class="absolute -top-2 right-6 cursor-pointer"
          src="@/assets/logos/certified-flag.svg"
          alt="Spreedly Blue Logo"
          @click="togglePopup"
          @keyup.enter="togglePopup"
          id="certified-flag"
          tabindex="0"
        />
      </div>
      <div class="flex flex-row p-4">
        <div
          class="rounded-3xl px-2 text-white text-sm py-1"
          :class="{
            'bg-success-green': connection.connected,
            'bg-spreedly-gray-500': !connection.connected,
          }"
        >
          {{
            connection.connected
              ? $t("connectionCount", {
                  count:
                    connection.connection_counts.production_count +
                    connection.connection_counts.sandbox_count,
                })
              : $t("notConnected")
          }}
        </div>
      </div>
      <div class="flex flex-row justify-between px-4">
        <img
          :src="getPartnerImageSrc(connection.partner)"
          :alt="`${connection.company_name} logo`"
          class="object-contain h-10 max-w-40 flex-shrink-0"
        />
        <Button
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          :id="`marketplace-manage-gateways-button-${connection.gateway_type}`"
          icon="pi pi-arrow-right"
          type="button"
          icon-pos="right"
          text
          :label="$t('gateway_.manage')"
          @click="
            router.push({
              name: 'PartnerGatewayList',
              params: { id: connection.gateway_type },
            })
          "
        ></Button>
      </div>
    </div>
  </div>
  <OverlayPanel
    id="premier-overlay-panel"
    ref="op"
    :pt="{
      root: 'bg-spreedly-gray-700 bg-opacity-95 text-white font-poppins text-sm',
    }"
  >
    <div class="flex flex-col w-[350px]">
      <div class="pb-4">
        {{ $t("gateway_.certifiedTooltip") }}
      </div>
      <a
        :href="`${docsUrl}/docs/spreedly-one-certification`"
        target="_blank"
        class="text-spreedly-blue-lighter underline"
        >{{ $t("gateway_.certifiedLink") }}</a
      >
    </div>
  </OverlayPanel>
</template>
<script setup lang="ts">
import type { GatewayOption } from "@/services/GatewayService";
import { getPartnerImageSrc } from "@/services/GatewayService";
import { defineModel, ref } from "vue";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

import router from "@/router";

const connections = defineModel<GatewayOption[]>("connections", {
  required: true,
});
const op = ref();
const docsUrl = import.meta.env.VITE_DOCS_URL;

const togglePopup = (event) => {
  op.value.toggle(event);
};
</script>

import * as Sentry from "@sentry/vue";
import http, { get, getList, post } from "@/http";
import type { Transaction } from "@/services/TransactionService";

export interface GatewayCount {
  sandbox_count: number;
  production_count: number;
}

export interface GatewayConnection {
  description?: string;
  key: string;
  gateway_type: string;
  created_at: string;
  sandbox: boolean;
  mode: string;
}

const partnerImagesImport = import.meta.glob("@/assets/logos/partners/*.*", {
  eager: true,
  import: "default",
  query: "?url",
});
const partnerImagePaths = Object.values(partnerImagesImport) as string[];

export type EnvironmentGateway = {
  name: string;
  gateway_type: string;
  key: string;
  state: string;
  created_at: string;
  description: string;

  sandbox?: boolean;
};

export type AuthMode = {
  auth_mode_type: string;
  name: string;
  credentials: Credential[];
};

export type GatewayPayload = {
  gateway_type: string;
  description: string;
  sandbox: boolean;
  mode?: string;
  gateway_settings?: Record<string, string>;
  credentials?: Record<string, string>;
};

export type Credential = { name: string; label: string; safe: boolean };
export type Setting = { name: string; label: string; is_boolean: boolean };
export type GatewayError = { key: string; message: string; attribute?: string };

export type GatewayOption = {
  gateway_type: string;
  name: string;
  auth_modes: AuthMode[];
  gateway_settings: Setting[];
  homepage: string;
  company_name: string;
  composer_enabled: boolean;
  certified: boolean;
  partner: string;
  partnership_tier: string;
  connected: boolean;
  connection_counts: GatewayCount;
  payment_methods: string[];
  characteristics: string[];
};

export type GatewayDescriptionOnly = {
  description: string;
  key: string;
};

export type Gateway = {
  key: string;
  name: string;
  mode: string;
  gateway_type?: string;
  created_at?: string;
  state: string;
  description: string;
  sandbox?: boolean;
  environment_key?: string;
  organization_key?: string;
  credentials?: { name: string; value: string }[];
  gateway_settings?: GatewaySettings;
};

export type GatewaySettings = {
  enabled_network_tokens?: boolean | null;
  webhook_id?: string | null;
  transaction_id_management_enabled?: boolean | null;
};

export const PaymentMethodMap: Record<string, string> = {
  credit_card: "Credit Card",
  apple_pay: "Apple Pay",
  google_pay: "Google Pay",
  third_party_token: "Third Party Token",
  bank_account: "Bank Account",
  third_party_network_token: "Third Party Network Token",
  paypal: "PayPal",
  venmo: "Venmo",
  pix: "Pix",
  boleto_bancario: "Boleto Bancario",
  oxxo: "OXXO",
  mercado_pago: "Mercado Pago",
  third_party_apple_pay: "Third Party Apple Pay",
  third_party_google_pay: "Third Party Google Pay",
  bancontact: "Bancontact",
  blik: "Blik",
  giropay: "Giropay",
  ideal: "Ideal",
  klarna: "Klarna",
  multibanco: "Multibanco",
  sepa_direct_debit: "Sepa Direct Debit",
  sofort: "Sofort",
  sprel: "Sprel",
  trustly: "Trustly",
  stripe_apm: "Stripe APM",
};
export async function fetchGatewayOptions(
  orgKey: string,
  envKey: string
): Promise<GatewayOption[]> {
  return getList(
    `/organizations/${orgKey}/environments/${envKey}/list_supported_gateways`,
    "gateways"
  );
}

export async function anyActiveWorkflows(
  orgKey: string,
  envKey: string,
  gatewayId: string
) {
  try {
    return get<boolean>(
      `/organizations/${orgKey}/environments/${envKey}/any_active_workflows/${gatewayId}`,
      "any_active_workflows"
    );
  } catch (err) {
    return false;
  }
}

export async function fetchGatewayConnectionByGatewayType(
  orgKey: string,
  envKey: string,
  gateway_type: string
): Promise<GatewayConnection[]> {
  let url = `/organizations/${orgKey}/environments/${envKey}/connections_by_gateway_type?gateway_type=${gateway_type}`;
  return get<GatewayConnection[]>(url, "gateways");
}

export async function fetchComposerEnabledGatewayConnections(
  orgKey: string,
  envKey: string
): Promise<GatewayConnection[]> {
  let url = `/organizations/${orgKey}/environments/${envKey}/composer_enabled_gateway_connections`;
  return get<GatewayConnection[]>(url, "gateways");
}

export async function createEnvironmentGateway(
  orgKey: string,
  envKey: string,
  gateway: GatewayPayload
): Promise<EnvironmentGateway | null> {
  const url = `/organizations/${orgKey}/environments/${envKey}/gateways`;
  return post(url, gateway, "gateway");
}

export async function fetchGateway(
  orgKey: string,
  envKey: string,
  gatewayKey: string
): Promise<Gateway | null> {
  const url = `/organizations/${orgKey}/environments/${envKey}/gateways/${gatewayKey}`;
  return get(url, "gateway");
}

export async function fetchEnvironmentGateways(
  orgKey: string,
  envKey: string
): Promise<EnvironmentGateway[]> {
  const url = `/organizations/${orgKey}/environments/${envKey}/gateways`;
  return getList(url, "gateways");
}

export async function updateGateway(
  orgKey: string,
  envKey: string,
  gateway: Gateway | GatewayDescriptionOnly
): Promise<Gateway | null> {
  const url = `/organizations/${orgKey}/environments/${envKey}/gateways/${gateway.key}`;
  try {
    const response = await http().put(url, gateway);
    return response.data.gateway;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export function getPartnerImageSrc(parentCompanyId: string) {
  return partnerImagePaths.find((path) => path.includes(parentCompanyId));
}

export async function redactGateway(
  orgKey: string,
  envKey: string,
  gateway: Gateway
): Promise<Transaction | null> {
  const url = `/organizations/${orgKey}/environments/${envKey}/gateways/${gateway.key}/redact`;
  try {
    const response = await http().put(url);
    return response.data.transaction;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

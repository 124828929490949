<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'absolute block' : 'relative hidden'"
      @click="isOpen = false"
      class="inset-0 z-20 transition-opacity bg-black opacity-50 xl:hidden"
    ></div>
    <!-- End Backdrop -->
    <div
      class="flex flex-col h-full justify-between w-screen md:w-64 inset-y-0 left-0 z-30 scrollbar-hide transition duration-300 rounded-tr-3xl transform xl:translate-x-0 xl:static xl:inset-0"
      :class="
        isOpen ? 'translate-x-0 ease-out ' : '-translate-x-full ease-in fixed'
      "
    >
      <div
        v-if="!currentOrganization.active"
        class="relative rounded-tr-2xl pl-4 pr-6 pt-2 pb-12 -mb-10 text-spreedly-blue-800 bg-spreedly-yellow hover:cursor-pointer"
      >
        <div
          class="flex"
          v-tooltip="$t('sidebar.organizationStatus.tooltip.inactive')"
        >
          <div class="mr-2">{{ $t("nonProduction") }}</div>
          <SpreedlyIcon name="information-outline"></SpreedlyIcon>
        </div>
      </div>
      <div
        class="flex flex-col relative rounded-tr-2xl px-2 pt-2 pb-12 -mb-10 bg-spreedly-blue-800 text-white hover:cursor-pointer"
      >
        <div
          class="flex flex-row justify-between w-full rounded-md px-4 py-1"
          :class="{
            'bg-white bg-opacity-10':
              !isEnvironmentMenu && isEnvironmentMenuRoute,
          }"
          role="button"
          tabindex="0"
          @keyup.enter="toggleEnvironmentDrawer()"
          @click="toggleEnvironmentDrawer()"
        >
          <div class="flex flex-col min-w-0">
            <span class="font-normal text-xs">{{ $t("env.environment") }}</span>
            <Skeleton
              width="10rem"
              class="mt-2"
              v-if="!currentEnvironment.name || isLoadingEnvironments"
            ></Skeleton>
            <span
              v-else
              :title="currentEnvironment.name"
              class="font-bold text-xl text-ellipsis whitespace-nowrap overflow-hidden mr-5"
              >{{ currentEnvironment.name }}</span
            >
          </div>
          <SpreedlyIcon
            class="self-center"
            name="chevron-down"
            v-if="!isEnvironmentMenu"
          ></SpreedlyIcon>
          <SpreedlyIcon
            class="self-center"
            name="chevron-up"
            v-else
          ></SpreedlyIcon>
        </div>
        <div
          class="transition-all ease-out duration-100 bg-spreedly-blue-800"
          :class="{
            'h-0': !isEnvironmentMenu,
            'h-64': isEnvironmentMenu && environments.length > 1,
            'h-48': isEnvironmentMenu && environments.length === 1,
          }"
        >
          <div v-if="isEnvironmentMenu" class="flex flex-col">
            <nav class="flex-1 py-4">
              <button
                type="button"
                id="environment-settings-nav"
                class="text-white rounded-md w-full py-2 group flex text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                :class="{
                  '!font-bold bg-white bg-opacity-10':
                    $route.name === 'CurrentEnvironment',
                }"
                @click="
                  router.push({
                    name: 'CurrentEnvironment',
                    params: { id: currentEnvironment.key },
                  })
                "
              >
                <span class="ml-10">{{ $t("env.settings") }}</span>
              </button>
              <button
                type="button"
                id="environment-access-secrets-nav"
                v-if="store.hasPermission('organization.list_access_secrets')"
                class="text-white rounded-md w-full py-2 group flex text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                :class="{
                  '!font-bold bg-white bg-opacity-10':
                    $route.name === 'EnvironmentAccessSecrets',
                }"
                @click="
                  router.push({
                    name: 'EnvironmentAccessSecrets',
                  })
                "
              >
                <span class="ml-10">{{
                  $t("accessSecret.accessSecret", 2)
                }}</span>
              </button>
              <button
                type="button"
                v-if="store.hasPermission('environment.update')"
                id="environment-signing-secret-nav"
                class="text-white rounded-md w-full py-2 group flex text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                :class="{
                  '!font-bold bg-white bg-opacity-10':
                    $route.name === 'EnvironmentSigningSecret',
                }"
                @click="
                  router.push({
                    name: 'EnvironmentSigningSecret',
                  })
                "
              >
                <span class="ml-10">{{ $t("env.signingSecret") }}</span>
              </button>
            </nav>
            <hr class="solid" />
            <nav class="flex-1 py-4">
              <button
                type="button"
                id="switch-environment-nav"
                @click="router.push({ name: 'EnvironmentList' })"
                v-if="environments.length > 1"
                class="text-white rounded-md w-full py-2 group flex text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                :class="{
                  '!font-bold bg-white bg-opacity-10':
                    $route.name === 'EnvironmentList' ||
                    ($route.name === 'EnvironmentSettings' &&
                      currentEnvironment.key !== $route.params.id) ||
                    ($route.name === 'EnvironmentSettings' &&
                      currentEnvironment.key === $route.params.id &&
                      (
                        router.options.history.state.back?.toString() || ''
                      ).includes('/environments')),
                }"
              >
                <span class="ml-10">{{ $t("env.switch") }}</span>
              </button>
              <button
                type="button"
                id="create-environment-nav"
                v-if="store.hasPermission('organization.create_environment')"
                class="text-white rounded-md w-full py-2 group flex text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                :class="{
                  '!font-bold bg-white bg-opacity-10':
                    $route.name === 'EnvironmentCreate',
                }"
                @click="router.push({ name: 'EnvironmentCreate' })"
              >
                <span class="ml-10">{{ $t("env.create") }}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col justify-between h-full bg-spreedly-blue-600 rounded-tr-2xl relative"
      >
        <nav
          id="branded-drawer"
          class="flex-1 mt-5 pb-8"
          :aria-label="$t('sideNav')"
        >
          <button
            type="button"
            id="overview-nav"
            v-if="store.hasPermission('dashboard.read')"
            class="w-[calc(100%-1rem)] rounded-md ml-2 mb-2 text-left text-white group flex items-center py-2 text-base font-normal hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
            :class="{
              '!font-bold bg-white bg-opacity-20':
                $route.name === 'EnvironmentOverview',
            }"
            @click="router.push({ name: 'EnvironmentOverview' })"
          >
            <SpreedlyIcon
              name="speedometer"
              class="mr-3 ml-4 flex-shrink-0 h-6 w-6"
            ></SpreedlyIcon>
            <span class="flex-1">{{ $t("overview") }}</span>
          </button>
          <button
            type="button"
            v-if="store.hasPermission('gateway.read')"
            id="connectivity-nav"
            class="w-[calc(100%-1rem)] rounded-md ml-2 mb-2 text-left text-white group flex items-center py-2 text-base font-normal hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
            :class="{
              'bg-white bg-opacity-20':
                isConnectivityMenuRoute && !isConnectivityMenu,
            }"
            @click="toggleConnectivityDrawer()"
          >
            <SpreedlyIcon
              name="connection"
              class="mr-3 ml-4 flex-shrink-0 h-6 w-6"
            ></SpreedlyIcon>
            <span class="flex-1">{{ $t("connectivity") }}</span>
            <SpreedlyIcon
              class="mr-4"
              name="chevron-down"
              v-if="!isConnectivityMenu"
            ></SpreedlyIcon>
            <SpreedlyIcon class="mr-4" name="chevron-up" v-else></SpreedlyIcon>
          </button>
          <div
            class="transition-all ease-out duration-100 bg-spreedly-blue-800"
            :class="{
              'h-0': !isConnectivityMenu,
              'h-30': isConnectivityMenu,
            }"
          >
            <div v-if="isConnectivityMenu" class="flex flex-col mb-2">
              <nav class="p-2">
                <button
                  type="button"
                  id="marketplace-nav"
                  v-if="accessToMarketplace"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      $route.name === 'Marketplace',
                  }"
                  @click="router.push({ name: 'Marketplace' })"
                >
                  <span class="ml-10">{{ $t("marketplace") }}</span>
                </button>
                <button
                  type="button"
                  id="gateways-nav"
                  v-if="accessToGateways"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      $route.path.includes('/gateway'),
                  }"
                  @click="router.push({ name: 'GatewayList' })"
                >
                  <span class="ml-10">{{ $t("connections") }}</span>
                </button>
              </nav>
            </div>
          </div>
          <button
            type="button"
            v-if="
              store.hasPermission('dashboard.read') ||
              store.hasPermission('environment.read') ||
              store.hasPermission('transaction.read') ||
              accessToComposer
            "
            id="performance-nav"
            class="w-[calc(100%-1rem)] rounded-md ml-2 mb-2 text-left text-white group flex items-center py-2 text-base font-normal hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
            :class="{
              'bg-white bg-opacity-20':
                isPerformanceMenuRoute && !isPerformanceMenu,
            }"
            @click="togglePerformanceDrawer()"
          >
            <SpreedlyIcon
              name="transit-connection-variant"
              class="mr-3 ml-4 flex-shrink-0 h-6 w-6"
            ></SpreedlyIcon>
            <span class="flex-1">{{ $t("performance") }}</span>
            <SpreedlyIcon
              class="mr-4"
              name="chevron-down"
              v-if="!isPerformanceMenu"
            ></SpreedlyIcon>
            <SpreedlyIcon class="mr-4" name="chevron-up" v-else></SpreedlyIcon>
          </button>
          <div
            class="transition-all ease-out duration-100 bg-spreedly-blue-800"
            :class="{
              'h-0': !isPerformanceMenu,
              'h-30': isPerformanceMenu,
            }"
          >
            <div v-if="isPerformanceMenu" class="flex flex-col mb-2">
              <nav class="p-2">
                <button
                  type="button"
                  id="vault-nav"
                  v-if="store.hasPermission('environment.read')"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      $route.name === 'AdvancedVault',
                  }"
                  @click="router.push({ name: 'AdvancedVault' })"
                >
                  <span class="ml-10">{{ $t("advancedVault.vault") }}</span>
                </button>
                <button
                  type="button"
                  id="composer-nav"
                  v-if="accessToComposer"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10': composerPages.includes(
                      ($route.name || '').toString()
                    ),
                  }"
                  @click="router.push({ name: 'WorkflowList' })"
                >
                  <span class="ml-10">{{ $t("composer.title") }}</span>
                  <span
                    class="bg-spreedly-teal text-spreedly-gray-700 ml-4 px-2 text-xs font-semibold rounded-md"
                    >Early access</span
                  >
                </button>
                <button
                  type="button"
                  id="transactions-nav"
                  v-if="store.hasPermission('transaction.read')"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      transactionPages.includes(($route.name || '').toString()),
                  }"
                  @click="router.push({ name: 'TransactionList' })"
                >
                  <span class="ml-10">{{
                    $t("transactions.transactions")
                  }}</span>
                </button>
                <button
                  type="button"
                  id="reports-nav"
                  v-if="store.hasPermission('dashboard.read')"
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      $route.name &&
                      $route.name.toString().includes('Report') &&
                      !$route.name.toString().includes('Organization'),
                  }"
                  @click="router.push({ name: 'ReportEnvironmentOverview' })"
                >
                  <span class="ml-10">{{ $t("report", 2) }}</span>
                </button>
                <button
                  type="button"
                  id="custom-analytics-nav"
                  v-if="
                    currentOrganization.advanced_dashboard_enabled &&
                    store.hasPermission('dashboard.read')
                  "
                  class="text-white rounded-md w-full py-2 group flex items-center text-base font-normal hover:text-spreedly-blue-800 hover:bg-spreedly-blue-300 hover:cursor-pointer"
                  :class="{
                    '!font-bold bg-white bg-opacity-10':
                      $route.name === 'CustomAnalytics',
                  }"
                  @click="router.push({ name: 'CustomAnalytics' })"
                >
                  <span class="ml-10">{{ $t("customAnalytics") }}</span>
                </button>
              </nav>
            </div>
          </div>
          <button
            type="button"
            id="routing-rules-nav"
            v-if="
              store.hasAccessToRoutingRules &&
              store.hasPermission('routing_rules.update')
            "
            class="w-[calc(100%-1rem)] rounded-md ml-2 mb-2 text-left text-white group flex items-center py-2 text-base font-normal hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
            :class="{
              '!font-bold bg-white bg-opacity-20':
                $route.name === 'RoutingRules',
            }"
            @click="router.push({ name: 'RoutingRules' })"
          >
            <SpreedlyIcon
              name="call-split"
              class="mr-3 ml-4 flex-shrink-0 h-6 w-6"
            ></SpreedlyIcon>
            <span class="flex-1">{{ $t("routingRules") }}</span>
          </button>
        </nav>
        <TheOrganizationMenu />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  recallDrawerState,
  setConnectivityDrawerState,
  setEnvironmentDrawerState,
  setPerformanceDrawerState,
  useSidebar,
} from "@/composables/useSidebar";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import TheOrganizationMenu from "@/components/TheOrganizationMenu.vue";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import Skeleton from "primevue/skeleton";
import { computed, onMounted, watch } from "vue";
import { useBreakpoints } from "@/composables/useBreakpoints";
const route = useRoute();
const router = useRouter();
const { width } = useBreakpoints();
const { isOpen, isEnvironmentMenu, isConnectivityMenu, isPerformanceMenu } =
  useSidebar();
const store = useSettingsStore();
const accessToGateways = store.hasPermission("gateway.read");
const accessToComposer =
  store.hasPermission("workflow.read") && store.hasAccessToComposer;
const accessToMarketplace = store.hasAccessToMarketplace;
const transactionPages = [
  "TransactionList",
  "TransactionDetailJson",
  "TransactionDetailTranscript",
  "TransactionDetailSummary",
];

const composerPages = [
  "WorkflowList",
  "WorkflowCreate",
  "WorkflowSettings",
  "RecoverConfigurationList",
  "RecoverConfigurationCreate",
  "RecoverConfigurationSettings",
];

const performanceDrawerPages = [
  "AdvancedVault",
  "Composer",
  ...composerPages,
  ...transactionPages,
  "CustomAnalytics",
];

const environmentDrawerPages = [
  "CurrentEnvironment",
  "EnvironmentAccessSecrets",
  "EnvironmentSigningSecret",
  "EnvironmentList",
  "EnvironmentSettings",
  "EnvironmentCreate",
];
function toggleEnvironmentDrawer() {
  isEnvironmentMenu.value = !isEnvironmentMenu.value;
  setEnvironmentDrawerState(isEnvironmentMenu.value);
}

function toggleConnectivityDrawer() {
  isConnectivityMenu.value = !isConnectivityMenu.value;
  setConnectivityDrawerState(isConnectivityMenu.value);
}

function togglePerformanceDrawer() {
  isPerformanceMenu.value = !isPerformanceMenu.value;
  setPerformanceDrawerState(isPerformanceMenu.value);
}
const isEnvironmentMenuRoute = computed(() => {
  return environmentDrawerPages.includes(route.name?.toString() || "");
});
const isPerformanceMenuRoute = computed(() => {
  return (
    performanceDrawerPages.includes(route.name?.toString() || "") ||
    (route.name?.toString().includes("Report") &&
      !route.name?.toString().includes("Organization"))
  );
});

const isConnectivityMenuRoute = computed(() => {
  return (
    route.path.includes("/gateway") || route.name?.toString() === "Marketplace"
  );
});

const {
  currentOrganization,
  currentEnvironment,
  environments,
  isLoadingEnvironments,
} = storeToRefs(store);

onMounted(() => {
  recallDrawerState();
});

watch(
  () => route.name,
  () => {
    if (width.value <= 1023) {
      isOpen.value = false;
    }
  }
);
</script>

<style lang="css" scoped>
.rounded-item {
  border-radius: 0 50px 50px 0;
}

#branded-drawer {
  background-image: url("@/assets/logos/sidebar-background-logo.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #0077c8;
}

.p-skeleton {
  background-color: #00253e;
}
</style>
